import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService, IEarnResponse, IRequestOptions } from '../../http';
import { Fundraiser, GetTeamIncludeType } from '../../../shared/models';
import { withoutNullProperties } from '../../../shared/util';
import { CacheService } from '../utility/cache.service';

export interface GetFundraisersOptions {
  // Filter by fundraiser name (fuzzy) or promo code (exact).
  searchQuery: string;

  // Filter by fundraiser name only.
  name: string;

  zipCode: string;
  communityId: string;

  limit: number;
  offset: number;
}

interface LegacySearchFundraisersOptions {
  limit: number;
  teamsOnly: boolean;
  include: GetTeamIncludeType[];
}

@Injectable({
  providedIn: 'root'
})
export class FundraiserService {
  private cacheKeys = {
    fundraiser: 'getFundraiser',
    fundraisers: 'getFundraisers'
  };

  constructor(private http: HttpService,
              private cache: CacheService) { }

  /**
   * Get fundraisers based on a given set of criteria.
   */
  public getFundraisers(options: Partial<GetFundraisersOptions>): Observable<Fundraiser[]> {
    const reqOptions: IRequestOptions = {
      params: withoutNullProperties({
        limit: options.limit || 10,
        offset: options.offset || 0,
        search: options.searchQuery,
        zip: options.zipCode,
        parentCommunity: options.communityId,
        name: options.name
      })
    };

    const req$ = this.http.get<IEarnResponse & { fundraisers: Fundraiser[] }>('/fundraiser', reqOptions)
      .pipe(map(res => res.fundraisers));

    return this.cache.setIfNotExists(this.cacheKeys.fundraisers, req$, { ttl: 10 * 60 * 1000, args: options });
  }

  public getFundraiser(id: string, noCache = false): Observable<Fundraiser> {
    const req$ = this.http.get<IEarnResponse & { fundraiser: Fundraiser }>(`/fundraiser/${id}`)
      .pipe(map(res => res.fundraiser));

    return this.cache.setIfNotExists(this.cacheKeys.fundraiser, req$, { ttl: 10 * 60 * 1000, args: { id }, noCache });
  }

  public updateFundraiser(id: string, fundraiser: Partial<Fundraiser>): Observable<void> {
    return this.http.patch(`/admin/fundraiser/${id}`, { body: fundraiser });
  }

  /**
   * Search for fundraisers by name.
   */
  public searchFundraisers(search: string, options: Partial<LegacySearchFundraisersOptions> = {}): Observable<Fundraiser[]> {
    const reqOptions: any = {
      params: withoutNullProperties({
        search: search,
        limit: options.limit,
        teamsOnly: options.teamsOnly,
        include: options.include && options.include.join(',')
      })
    };

    return this.http.get<IEarnResponse & { fundraisers: Fundraiser[] }>('/v3/fundraiser', reqOptions)
      .pipe(map(res => {
        return res.fundraisers;
      }));
  }
}
