import { Injectable } from '@angular/core';
import { CookieOptions } from '../cookie.model';
import { CookieBase } from './cookie-base';

@Injectable({
  providedIn: 'root'
})
export class BusinessLocationCookie extends CookieBase {
  public readonly name: string = 'ht_business_location';
  public readonly options: CookieOptions = {};
}
