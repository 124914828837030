import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModule } from 'ngx-bootstrap/alert';
import { SharedModule } from '../../shared';
import { DownloadAppCtaComponent } from './download-app-cta/download-app-cta.component';
import { VerifyEmailCtaComponent } from './verify-email-cta/verify-email-cta.component';

const components = [
  DownloadAppCtaComponent,
  VerifyEmailCtaComponent,
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    AlertModule,
    SharedModule
  ]
})
export class GettingStartedModule { }
