import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IEarnResponse } from '../../../core';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  public message: string;
  public success = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    const response: IEarnResponse = this.route.snapshot.data.response;
    this.success = response.success;
    this.message = this.success ? 'Your email has been successfully verified. You may now sign in.'
                                : response.message;
  }
}
