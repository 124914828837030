import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { SignInFormComponent } from './sign-in-form/sign-in-form.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { RecaptchaModule } from '../../recaptcha/recaptcha.module';

const components = [
  SignInFormComponent,
  SignUpFormComponent
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    AlertModule,
    RecaptchaModule,
  ]
})
export class AuthFormModule { }
