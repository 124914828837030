import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { LayoutComponent } from './layout.component';
import { MatButtonModule } from '@angular/material/button';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { AccountMenuComponent } from './account-menu/account-menu.component';
import { SharedModule } from '../../shared';
import { FunnelCardLayoutComponent } from './funnel-card-layout/funnel-card-layout.component';

const components = [
  HeaderComponent,
  FooterComponent,
  LayoutComponent,
  DefaultLayoutComponent,
  AccountMenuComponent,
  FunnelCardLayoutComponent
];

@NgModule({
  declarations: [...components],
  providers: [],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    SharedModule,
  ],
  exports: [...components]
})
export class LayoutModule { }
