import { Component, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ILayoutData } from '../layout.service';
import { LayoutTheme } from '../models/layout-theme.enum';
import { LayoutMode } from '../models/layout-mode.enum';
import { BrowserDetectService, BrowserName, ImagesService } from '../../../core';

@Component({
  selector: 'app-funnel-card-layout',
  templateUrl: './funnel-card-layout.component.html',
  styleUrls: ['./funnel-card-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FunnelCardLayoutComponent {
  public readonly defaultOptions: ILayoutData = {
    theme: LayoutTheme.TRANSPARENT,
    mode: LayoutMode.FUNNEL,
    hideFooterDivider: true,
    noContentPadding: true
  };

  public style: { [key: string]: SafeStyle } = {};

  constructor(private images: ImagesService,
              private sanitizer: DomSanitizer,
              private browserDetect: BrowserDetectService) {
    if (this.browserDetect.browserName !== BrowserName.SAFARI) {
      this.style['background-image'] = this.sanitizer.bypassSecurityTrustStyle(`url('${this.images.background}')`);
    } else {
      this.style.background = 'var(--bg-fallback)';
    }
  }
}
