import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AccountService, Logger } from '../../../core';
import { environment } from '../../../../environments/environment.dev';

@Component({
  selector: 'app-verify-email-cta',
  templateUrl: './verify-email-cta.component.html',
  styleUrls: ['./verify-email-cta.component.scss']
})
export class VerifyEmailCtaComponent implements OnInit {
  public readonly brandName = environment.brandName;

  public alert: { type: string, msg: string } = {
    type: 'success',
    msg: null
  };

  public isResendingEmail = false;

  constructor(private accountService: AccountService,
              private logger: Logger) { }

  ngOnInit(): void {
  }

  public resendVerificationEmail(): void {
    this.isResendingEmail = true;
    this.accountService.resendVerificationEmail()
      .pipe(finalize(() => this.isResendingEmail = false))
      .subscribe(
        () => {
          this.alert = {
            type: 'success',
            msg: 'Verification email sent.'
          };
        },
        err => {
          this.logger.error(err);
          this.alert = {
            type: 'danger',
            msg: err
          };
        }
      );
  }

}
