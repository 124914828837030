<form (ngSubmit)="onSubmit()" #loginForm="ngForm">
  <div class="form-group text-left">
    <label>Username</label>
    <input autocomplete="username" type="text" id="username" name="username" class="form-control"
           [disabled]="loading" [(ngModel)]="model.username" required autofocus>
  </div>
  <div class="form-group text-left">
    <label>Password</label>
    <input autocomplete="current-password" type="password" id="password" name="password" class="form-control"
           [disabled]="loading" [(ngModel)]="model.password" required>
  </div>
  <alert [type]="alert.type" *ngIf="alert?.msg" (onClose)="alertClose()" dismissible="true">
    {{ alert.msg }}
  </alert>
  <div class="color-light size-14 text-center">
    Forgot your
    <a class="app-link" routerLink="/account/recovery" fragment="username" target="_blank">username</a>
    or <a class="app-link" routerLink="/account/recovery" fragment="password" target="_blank">password</a>?
  </div>
  <button type="submit" class="btn btn-primary btn-padded" [disabled]="!loginForm.form.valid || loading">
    <i class="fas fa-spinner fa-spin" *ngIf="loading"></i>
    <span *ngIf="!loading">Sign In</span>
  </button>
</form>
