// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { envBase } from './environment.base';

/**
 * To use with My HuTerra (Android only):
 * - When using emulator, use 10.0.2.2
 * - When using actual device, use your local IP address.
 *
 * In either of these cases, update ip address being used in environment.ts in My HuTerra to use the same value.
 */
const ipAddress = 'localhost';

export const environment = Object.assign({}, envBase, {
  env: 'dev',
  /**
   * The API the frontend speaks to.
   *
   * @remarks
   * NOT Earn2, but the URL to this project's sails API routes.
   */
  apiUrl: `http://${ipAddress}:6080/api/`,
  earnApiUrl: `http://${ipAddress}:7080/v3/`,
  huterra: {
    website: {
      // There is no local version of the WordPress site.
      url: 'https://test.myraisify.com/',
      legacy: {
        url: `http://${ipAddress}:8080/`,
      }
    },
    analytics: {
      google: {
        accountId: 'UA-32668042-3'
      }
    },
  },
  raiseUp: {
    website: {
      url: 'http://stage.raiseup.org/'
    }
  },
  plaid: {
    publicKey: '4ca4d776242b414072c2f3df5f7dab',
    environment: 'sandbox',
    linkScriptUrl: 'https://cdn.plaid.com/link/v2/stable/link-initialize.js'
  },
  google: {
    apiKey: 'AIzaSyDQ7F3hPmsIu48FbyB0cJHNey-KpijgH1A',
    recaptchaSiteKey: '6LclT5clAAAAAGabA_V2M4sdMFkdXl1koSw7z3hy',
  },
  log: {
    level: 'info'
  }
});

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
