<h5>Verify Your Email Address</h5>

<p class="color-light">
  We've sent you a link to verify your email address. Verified accounts have access to all features in {{ brandName }}, including buying gift cards.
</p>

<alert [type]="alert.type" *ngIf="alert?.msg" dismissible="true">
  {{alert.msg}}
</alert>

<button class="btn btn-outline-primary btn-padded" [disabled]="isResendingEmail"
        (click)="resendVerificationEmail()">
  <i class="fas fa-spinner fa-spin" *ngIf="isResendingEmail"></i>
  <span *ngIf="!isResendingEmail">Resend Email</span>
</button>
