import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { HttpService, IEarnResponse, AuthService, CacheService } from '../../core';
import { Allocation } from '../../shared/models';
import { AddAllocation, LegacyAllocation} from '../../shared/models/legacy/allocation.model';
import { withoutNullProperties } from '../../shared/util';

@Injectable({
  providedIn: 'root'
})
export class AllocationService implements OnDestroy {
  private readonly ALLOCATION_CACHE_KEY = 'getCurrentAllocations';

  private destroy$ = new Subject<void>();

  constructor(private http: HttpService, private cache: CacheService, private auth: AuthService) {
    this.auth.authChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.clearCache();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Get a user's current fundraiser allocations.
   */
  public getCurrentAllocations(noCache = false): Observable<LegacyAllocation[]> {
    const req$ = this.http.get<IEarnResponse & { allocations: LegacyAllocation[] }>('/me/allocation?includeFundraiserDetails=true')
      .pipe(map(res => res.allocations));

    return this.cache.setIfNotExists(this.ALLOCATION_CACHE_KEY, req$, { ttl: 10 * 60 * 1000, noCache });
  }

  public getMemberAllocations(memberId: string, includeDetails: boolean = null,
                              includeOrgDetails: boolean = null, includeFundraiserDetails: boolean = false):
    Observable<Allocation[]> {
    const options = {
      params: withoutNullProperties({
        memberId: memberId,
        includeDetails: includeDetails,
        includeOrgDetails: includeOrgDetails,
        includeFundraiserDetails: includeFundraiserDetails
      })
    };

    return this.http.get<IEarnResponse & { allocations: Allocation[] }>(`/admin/memberAllocations`, options)
      .pipe(map(res => {
        return res.allocations;
      }));
  }

  public addAllocation(model: AddAllocation): Observable<any> {
    const options = { body: [ model ] };
    return this.http.post('/me/allocation', options)
      .pipe(tap(() => this.clearCache()));
  }

  public addAllocations(model: AddAllocation[]): Observable<any> {
    const options = { body: model };
    return this.http.post('/me/allocation', options)
      .pipe(tap(() => this.clearCache()));
  }

  public deleteAllocation(id: string): Observable<any> {
    return this.http.delete(`/me/allocation/${id}`)
      .pipe(tap(() => this.clearCache()));
  }

  public clearCache(): void {
    this.cache.delete(this.ALLOCATION_CACHE_KEY, { force: true });
  }
}
