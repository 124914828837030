import { Injectable } from '@angular/core';

import { UserRole, StorageKey } from 'app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public getUserRoles(): UserRole[] {
    return this.get(StorageKey.UserRoles) || [];
  }

  public get<T>(key: string): T {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : value;
  }

  public set(key: string, value: any): void {
    if (value == null) localStorage.removeItem(key);
    else localStorage.setItem(key, JSON.stringify(value));
  }
}
