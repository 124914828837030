import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DialogButton, DialogComponent } from "app/shared/components/dialog/dialog.component";

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) {}

  show(title: string,
       message: string,
       buttons: DialogButton[] = [{text: 'Close'}],
       dialogConfig?: MatDialogConfig) {
    const config: MatDialogConfig = Object.assign({}, dialogConfig, {data: {title, message, buttons}});
    return this.dialog.open(DialogComponent, config);
  }
}
