<h3 mat-dialog-title *ngIf="title">{{title}}</h3>
<div mat-dialog-content>
  <p [innerHtml]="message"></p>
</div>
<div mat-dialog-actions>
  <ng-container *ngFor="let button of buttons">
    <button *ngIf="!button.type || button.type === 'basic'" mat-button
            [color]="button.color" [ngClass]="button.class"
            [mat-dialog-close]="true" (click)="button.click && button.click()">
      {{button.text}}
    </button>

    <button *ngIf="button.type === 'flat'" mat-flat-button
            [color]="button.color" [ngClass]="button.class"
            [mat-dialog-close]="true" (click)="button.click && button.click()">
      {{button.text}}
    </button>
  </ng-container>
</div>
