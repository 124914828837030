import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LegacyTeamService } from '../../core/services';
import { DialogService } from '../../core/services/dialog/dialog.service';
import { Team, TeamCreationValidation } from '../../shared/models';
import { AlreadyOwnedTeamsDialogComponent } from './already-owned-teams-dialog/already-owned-teams-dialog.component';
import { CreateMyTeamDialogComponent } from './create-my-team-dialog/create-my-team-dialog.component';

class CreateTeamConfirmation {
  /**
   * An existing team a user selected that needs to be added to their allocations.
   */
  existingTeamToAdd?: Team;

  /**
   * Whether the team was successfully created.
   */
  teamCreated: boolean = false;

  constructor(existingTeamToAdd?: Team, teamCreated?: boolean) {
    this.existingTeamToAdd = existingTeamToAdd;
    this.teamCreated = teamCreated;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CreateMyTeamService {

  constructor(private teamService: LegacyTeamService,
              private dialogService: DialogService,
              private dialog: MatDialog) { }

  /**
   * Present and handle the dialog (and its guards) for a user to create their own team.
   */
  public async createMyTeam(fundraiserId: string, validation: TeamCreationValidation): Promise<CreateTeamConfirmation> {
    const adminConfirm = await this.confirmCreateTeamIfAdmin(validation);
    if (!adminConfirm) return new CreateTeamConfirmation();

    const teamConfirm = await this.confirmCreateTeamIfHaveTeams(validation);
    if (teamConfirm && teamConfirm.selectedTeam) {
      return new CreateTeamConfirmation(teamConfirm.selectedTeam);
    } else if (!teamConfirm || !teamConfirm.confirm) {
      return new CreateTeamConfirmation();
    }

    return this.presentAndHandleDialog(fundraiserId, validation);
  }

  private async confirmCreateTeamIfAdmin(validation: TeamCreationValidation): Promise<boolean> {
    if (!validation.warnings.isAdmin) {
      return true;
    }

    return new Promise((resolve, reject) => {
      this.dialogService.show(
        'Heads Up!',
        `<p>You're an admin of this fundraiser. If you create a team, <strong>you will become the owner of the team.</strong></p>`
        + `<p>Do you want to continue creating a team?</p>`,
        [
          { text: 'Create a Team', type: 'flat', color: 'primary', click: () => resolve(true) },
          { text: 'Cancel', type: 'flat', click: () => resolve(false) }
        ],
        {
          panelClass: 'text-center',
          maxWidth: 500,
          autoFocus: false
        });
    });
  }

  private async confirmCreateTeamIfHaveTeams(validation: TeamCreationValidation)
      : Promise<{ confirm: boolean, selectedTeam?: Team }> {
    const myTeams = validation.warnings.myTeams;
    if (!myTeams || myTeams.length === 0) {
      return { confirm: true };
    }

    const config: MatDialogConfig = {
      maxWidth: 500,
      autoFocus: false,
      data: { ownedTeams: myTeams }
    };
    const dialogRef = this.dialog.open(AlreadyOwnedTeamsDialogComponent, config);
    return dialogRef.afterClosed().toPromise();
  }

  private async presentAndHandleDialog(fundraiserId: string, validation: TeamCreationValidation)
      : Promise<CreateTeamConfirmation> {
    const config = {
      maxWidth: 500,
      data: {
        fundraiserId: fundraiserId,
        fundsTeamSplit: validation.fundsTeamSplit
      }
    };
    const dialogRef = this.dialog.open(CreateMyTeamDialogComponent, config);
    const dismissData: { teamCreated: boolean } = await dialogRef.afterClosed().toPromise();
    return new CreateTeamConfirmation(null, !!(dismissData && dismissData.teamCreated));
  }
}
