import { Injectable } from '@angular/core';
import { HttpService, IEarnResponse } from '../../http';
import { Organization } from '../../../shared/models';
import { first, map } from 'rxjs/operators';
import {Observable} from 'rxjs';
import {withoutNullProperties} from '../../../shared/util';
import { CacheService } from '../utility/cache.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private cacheKeys = {
    fein: 'getFEIN'
  };

  constructor(private http: HttpService,
              private cache: CacheService) { }

  /**
   * Get organization based fein.
   * @param fein - organization fein
   */
  public getOrganization(fein: string) {
    return this.http.get<IEarnResponse & { organization: Organization }>(`/admin/organization/${fein}`)
      .pipe(map(res => res.organization), first());
  }

  /**
   * Get all organization data.
   */
  public getOrganizationStates() {
    return this.http.get<{ stateCodes: {} }>('admin/organization/states')
      .pipe(map(res => {
        return res.stateCodes;
      }));
  }

  /**
   * Get FEIN of organization.
   */
  public getFEIN(id: string): Observable<string> {
    const req$ = this.http.get<IEarnResponse & { fein: string }>(`/admin/organization/${id}/fein`)
      .pipe(map(res => res.fein));

    return this.cache.setIfNotExists(this.cacheKeys.fein, req$, { args: { id } });
  }
}
