import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';

import { StorageKey, UserLogin, UserRole } from 'app/shared/models';
import { LoginResponse } from './models/login-response.model';
import { HttpService, IEarnResponse } from '../http';
import { AuthenticatedResponse, AuthorizedResponse } from './models/authenticated-response.model';
import { UserRegistration, UserRegistrationResponse } from '../../shared/models/auth.model';
import { withoutNullProperties } from '../../shared/util';
import { StorageService } from '../services/storage/storage.service';
import { CacheService } from '../services/utility/cache.service';
import { AuthCookieService } from '../services/utility/cookie/auth-cookie.service';
import { AccountMenuService } from '../services';

interface UpdateAuthOptions {
  isAuthenticated: boolean;
  username?: string;
  displayName?: string;
  roles?: UserRole[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * Triggered whenever a user's login state changes.
   * When checking for authentication, should use {@link isAuthenticated} whenever async is possible.
   */
  public authChange$ = new BehaviorSubject<{ isAuthenticated: boolean }>({ isAuthenticated: false });

  constructor(private injector: Injector,
              private http: HttpService,
              private storage: StorageService,
              private cache: CacheService,
              private authCookieService: AuthCookieService,
              private accountMenu: AccountMenuService) {
    /**
     * Check if the user is authenticated synchronously. Used to set initial state to improve UX.
     * Guards will update user's true authentication status asynchronously via {@link isAuthenticated}.
     */
    const username = this.storage.get(StorageKey.Username);
    if (username) this.updateAuth({ isAuthenticated: true });
  }

  /**
   * Check whether the current user is logged in.
   */
  public isAuthenticated(): Observable<boolean> {
    return this.http.get('/auth/authenticated')
      .pipe(
        map((data: AuthenticatedResponse) => {
          const isAuthenticated = !!(data && data.isAuthenticated);
          if (this.authChange$.value.isAuthenticated !== isAuthenticated) {
            this.updateAuth({ isAuthenticated });
          }
          return isAuthenticated;
        }),
        catchError(err => {
          if (err === 'Forbidden' && this.authChange$.value.isAuthenticated) {
            this.updateAuth({ isAuthenticated: false });
          }

          return of(false);
        })
      );
  }

  /**
   * Check whether the current user is considered authorized based on a given role.
   *
   * @remarks
   * Admins are authorized for all roles.
   *
   * @param role - Role to validate user against.
   */
  public isAuthorized(role: UserRole): Observable<boolean> {
    return this.http.get(`/auth/authorized`, { params: withoutNullProperties({ role }) })
      .pipe(
        map((data: AuthorizedResponse) => data ? data.isAuthorized : false),
        catchError(err => {
          if (err === 'Forbidden' && !role && this.authChange$.value.isAuthenticated) {
            this.updateAuth({ isAuthenticated: false });
          }

          return of(false);
        })
      );
  }

  public login(model: UserLogin): Observable<LoginResponse> {
    return this.http.post<LoginResponse>('/auth/login', { body: model })
      .pipe(
        tap(data => {
          this.updateAuth({
            isAuthenticated: true,
            username: data.username,
            displayName: data.name,
            roles: data.roles
          });
        }),
        first()
      );
  }

  public register(model: UserRegistration) {
    return this.http.post<UserRegistrationResponse>('/auth/register', {body: model})
      .pipe(tap(() => {
        this.updateAuth({
          isAuthenticated: true,
          username: model.username,
          displayName: model.firstName,
          roles: []
        });
      }));
  }

  /**
   * Log user out and navigate to the homepage.
   */
  public logout(): Observable<{}> {
    this.updateAuth({ isAuthenticated: false });
    return this.http.post('/auth/logout', { responseType: 'text' });
  }

  public tokenTransfer(): Observable<string> {
    return this.http.get<IEarnResponse & { transferToken: string }>('/auth/tokenTransfer')
      .pipe(map(res => res.transferToken));
  }

  public tokenTransferLogin(token: string) {
    return this.http.post<any>(`/auth/tokenTransfer/login`, {
      body: {
        transferToken: token
      }
    })
  }

  public updateAuth(options: UpdateAuthOptions): void {
    this.cache.clear();

    if (!options.isAuthenticated) {
      this.authCookieService.deleteAll();
      options = { ...options, ...{ roles: [], username: null, displayName: null }};
    }

    if (options.roles !== undefined) this.storage.set(StorageKey.UserRoles, options.roles);
    if (options.username !== undefined) this.storage.set(StorageKey.Username, options.username);
    if (options.displayName !== undefined) this.storage.set(StorageKey.DisplayName, options.displayName);

    this.authChange$.next({ isAuthenticated: options.isAuthenticated });
    this.accountMenu.update(options.isAuthenticated);
  }
}

