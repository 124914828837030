<form (ngSubmit)="createMyTeam()" #createTeamForm="ngForm">
  <div class="row">
    <div class="form-group col-12">
      <label>City</label>
      <input type="text" id="city" name="city" class="form-control" [(ngModel)]="model.city" autofocus>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-12">
      <label class="required">School Name</label>
      <input type="text" id="school-name" name="schoolName" class="form-control"
             [(ngModel)]="model.schoolName" required>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-sm-12 col-md-4">
      <label>Title</label>
      <input type="text" id="title" name="title" class="form-control" [(ngModel)]="model.title">
    </div>

    <div class="form-group col-sm-12 col-md-8">
      <label>Last Name</label>
      <input type="text" id="last-name" name="lastName" class="form-control" [(ngModel)]="model.lastName">
    </div>
  </div>

  <div class="row">
    <div class="form-group col-12">
      <label class="required">Class Name</label>
      <input type="text" id="class-name" name="className" class="form-control"
             [(ngModel)]="model.className" required>
    </div>
  </div>

  <div class="row team-name-row">
    <div class="form-group col-12">
      <label>Team Name</label>
      <input type="text" id="team-name" name="teamName" class="form-control"
             [ngModel]="teamName" required disabled aria-describedby="team-name-hint">
      <small id="team-name-hint" class="form-text text-muted">
        Your team name is automatically generated by the information you've provided us.
      </small>
    </div>
  </div>

  <div class="row">
    <alert [type]="alert.type" *ngIf="alert?.msg" dismissible="true" class="col-12">
      {{ alert.msg }}
    </alert>
  </div>

  <div class="row">
    <div class="col-12 text-center">
      <button type="submit" class="btn btn-primary btn-padded" [disabled]="!createTeamForm.form.valid || isSubmitting">
        <i class="fas fa-spinner fa-spin" *ngIf="isSubmitting"></i>
        <span *ngIf="!isSubmitting">Create a Team</span>
      </button>
    </div>
  </div>
</form>
