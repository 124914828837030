<table mat-table [dataSource]="dataSource">
  <!--#region Header Definitions-->
  <ng-container matColumnDef="row">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Row</th>
    <td mat-cell *matCellDef="let row">{{row.row}}</td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let row">{{row.name}}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let row">{{row.status}}</td>
  </ng-container>
  <!--#endregion-->

  <!-- Header and Row Declarations -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
