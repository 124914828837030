<div class="section">
  <h4>You're almost ready to start earning!</h4>
</div>

<div class="section" *ngIf="mode === 'signup'">
  <app-verify-email-cta></app-verify-email-cta>
</div>

<div class="final-steps-content section">
  <app-download-app-cta></app-download-app-cta>

<!--  Disabled until browser extensions are rebranded. -->
<!--  <section class="download-browser-extension-section" *ngIf="!browserDetect.isMobile">-->
<!--    <h5>Download the Browser Extension</h5>-->

<!--    <p class="color-light">-->
<!--      Our RaiseUp browser assistant automatically asks if you want to earn rewards every time you shop online at any of our participating name brand stores. Just shop online like you always do, click the prompt, and let us take care of the rest.-->
<!--    </p>-->

<!--    <div class="browser-extension-video-container">-->
<!--&lt;!&ndash;      TODO: update to raisify video instead of huterra&ndash;&gt;-->
<!--      <iframe width="360" height="202" src="https://www.youtube.com/embed/k71KMm7mg_A?rel=0" frameborder="0"-->
<!--              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>-->
<!--      </iframe>-->
<!--    </div>-->

<!--    <div class="browser-extension-downloads-container">-->
<!--      <div class="promote-primary-browser" *ngIf="browserExt?.targets.primary; else noPrimaryBrowserTargetTemplate">-->
<!--        <ng-container *ngTemplateOutlet="browserTargetBtn; context: { $implicit: browserExt?.targets.primary }">-->
<!--        </ng-container>-->

<!--        <a class="browser-target-secondary" [href]="browserExt?.targets.secondary?.url">-->
<!--          <span>Or download for {{browserExt?.targets.secondary?.browser | titlecase}}</span>-->
<!--          <img [src]="browserExt?.targets.secondary?.logo" />-->
<!--        </a>-->
<!--      </div>-->

<!--      <ng-template #noPrimaryBrowserTargetTemplate>-->
<!--        <div class="no-promote-primary-browser">-->
<!--          <ng-container *ngTemplateOutlet="browserTargetBtn; context: { $implicit: browserExt?.extensions.chrome }">-->
<!--          </ng-container>-->
<!--          <ng-container *ngTemplateOutlet="browserTargetBtn; context: { $implicit: browserExt?.extensions.firefox }">-->
<!--          </ng-container>-->
<!--        </div>-->
<!--      </ng-template>-->
<!--    </div>-->
<!--  </section>-->
</div>

<div class="section">
  <a class="btn btn-primary shop-online-button btn-padded" routerLink="/account">Finish</a>
</div>

<ng-template #browserTargetBtn let-extension>
  <a class="btn btn-outline-primary" [href]="extension?.url"
     [target]="extension?.target || '_self'">
    <span>Download for {{extension?.browser | titlecase}}</span>
    <img [src]="extension?.logo" />
  </a>
</ng-template>
