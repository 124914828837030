import { ILatLng, ILatLngBounds } from '../lat-lng.model';
import { withoutNullProperties } from '../../util';

export type BusinessShowOnly = 'GIVE' | 'DEALS' | 'UNCLAIMED' | 'ONLINE' | 'GIFTCARDS' | 'LOCAL';
export type BusinessExclude = 'LOCAL';

export enum BusinessSortName {
  Distance = 'dist',
  Give = 'give',
  Name = 'name',
  Deal = 'deal'
}

export interface BusinessSort {
  name: BusinessSortName;
  direction: 'asc' | 'desc';
}

export class GetBusinessOptions {
  /**
   * Location to pull businesses around using {@link radius} or {@link bounds}.
   *
   * Not required when pulling businesses for online or gift cards.
   */
  location?: ILatLng;

  /**
   * The location manually set by the user. When provided, used as the origin when calculating {@link Business.distance}.
   */
  searchLocation?: ILatLng;

  /**
   * Radius around {@link location} to pull businesses.
   * Mutually exclusive to {@link bounds}.
   */
  radius?: number;

  /**
   * Bounds in which businesses must be contained in.
   * Mutually exclusive to {@link radius}.
   */
  bounds?: ILatLngBounds;

  /**
   * List of categories to pull businesses by, using the category code.
   * @see {@link Category#code}
   */
  category?: string[];

  /**
   * How to sort results.
   * Defaults to sorting by highest give amount and then closest distance.
   */
  sort: BusinessSort[] = [
    { name: BusinessSortName.Give, direction: 'desc' },
    { name: BusinessSortName.Distance, direction: 'asc' }
  ];

  searchQuery?: string;

  associationId?: string;
  eventId?: string;

  /**
   * Number of rows to return. For pagination.
   */
  limit?: number;

  /**
   * Row number to start with. For pagination.
   */
  start?: number;

  startsWith?: string;

  /**
   * Extra attributes to pull back with the businesses.
   */
  include: ('community' | 'placedetail' | 'corporatebusinesses' | 'memberactivecardbalance' | 'earliestLocalGiveStartDate' | 'onlinestore' | 'parentgive')[];
  exclude: BusinessExclude[];

  showOnly?: BusinessShowOnly[];

  constructor(data: Partial<GetBusinessOptions>) {
    Object.assign(this, data);
  }

  public toParams(): { [param: string]: string } {
    const params: any = {
      location: this.location ? `${this.location.lat},${this.location.lng}` : null,
      searchLocation: this.searchLocation ? `${this.searchLocation.lat},${this.searchLocation.lng}` : null,
      category: this.category && this.category.length > 0 ? this.category.join(',') : null,
      associationId: this.associationId,
      eventId: this.eventId,
      include: this.include ? this.include.join(',') : null,
      exclude: this.exclude?.join(','),
      limit: this.limit,
      start: this.start,
      search: this.searchQuery,
      showonly: this.showOnly?.join(','),
      sort: this.sort.map(aSort => `${aSort.name}:${aSort.direction}`).join(','),
      startsWith: this.startsWith
    };

    // Bounds and radius are mutually exclusive.
    if (this.bounds) {
      params.searchbox = `${this.bounds.northeast.lat},${this.bounds.northeast.lng},`
        + `${this.bounds.southwest.lat},${this.bounds.southwest.lng}`;
    } else if (this.radius) {
      params.radius = this.radius;
    }

    return withoutNullProperties(params);
  }
}
