import { Component } from '@angular/core';
import { Logger, LegacyTeamService } from '../../../../core';
import { isNullOrEmpty } from '../../../../shared/util';
import { CreateTeamStepComponentBase } from '../create-team-step-base.component';

interface TeamNameParts {
  city: string;
  schoolName: string;
  title: string;
  lastName: string;
  className: string;
}

@Component({
  selector: 'app-create-classroom-team-step',
  templateUrl: './create-classroom-team-step.component.html',
  styleUrls: ['./create-classroom-team-step.component.scss']
})
export class CreateClassroomTeamStepComponent extends CreateTeamStepComponentBase {
  public model: TeamNameParts = {
    city: null,
    schoolName: null,
    title: null,
    lastName: null,
    className: null
  };

  public get teamName(): string {
    const teacher = (this.model.title ? `${this.model.title} ` : '') + (this.model.lastName || '');
    const nameParts: string[] = [
      this.model.city, this.model.schoolName, teacher, this.model.className
    ];

    return nameParts.filter(val => !isNullOrEmpty(val)).join(' ');
  }

  constructor(protected teamService: LegacyTeamService,
              protected logger: Logger) {
    super(teamService, logger);
  }
}
