import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

export interface AppBadge {
  url: string;
  badge: string;
  alt: string;
}

@Component({
  selector: 'app-badge',
  templateUrl: './app-badge.component.html',
  styleUrls: ['./app-badge.component.scss']
})
export class AppBadgeComponent implements OnInit {
  @Input() type: 'android' | 'ios';

  public readonly badges = environment.mobileApp;

  constructor() { }

  ngOnInit(): void {
  }

}
