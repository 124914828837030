import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class Logger {
  public debug(value: any, ...rest: any[]): void {
    if (environment.env !== 'prod') {
      console.debug(value, ...rest); // tslint:disable-line:no-console
    }
  }

  public info(value: any, ...rest: any[]): void {
    console.info(value, ...rest); // tslint:disable-line:no-console
  }

  public warn(value: any, ...rest: any[]): void {
    console.warn(value, ...rest);
  }

  public error(value: any, ...rest: any[]): void {
    console.error(value, ...rest);
  }
}
