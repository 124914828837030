import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IEarnResponse, HttpService, IRequestOptions } from '../../http';
import { CacheService } from '../utility/cache.service';
import { GeocodeData, ILatLng } from '../../../shared/models';
import { environment } from '../../../../environments/environment';

export interface City {
  city: string;

  /**
   * Abbreviated state name.
   */
  state: string;

  /**
   * Whether the city is the primary city associated with a zip code.
   */
  primary: boolean;
}

@Injectable()
export class GoogleService {
  constructor(private http: HttpService,
              private cache: CacheService) { }

  public geocode(address: string): Observable<GeocodeData[]> {
    const req$ = this.http.get<IEarnResponse & { result: GeocodeData[] }>('/google/searchAddress', { params: { address } })
      .pipe(map(res => res.result));

    return this.cache.setIfNotExists('geocode', req$, { args: address });
  }

  /**
   * Geolocate a user using their IP address.
   */
  public geolocate(): Observable<ILatLng> {
    const reqOptions: IRequestOptions = {
      params: { key: environment.google.apiKey },
      body: { considerIp: true }
    };

    return this.http.post<{ location: ILatLng, accuracy: number }>(`https://www.googleapis.com/geolocation/v1/geolocate`, reqOptions)
      .pipe(map(res => res.location));
  }

  /**
   * Get the primary city associated with a zip code.
   */
  getZipPrimary(zip: string): Observable<City> {
    return this.http.get<IEarnResponse & { cities: City[] }>(`/city?zip=${zip}`)
      .pipe(map(results => results.cities.find(x => x.primary)));
  }
}
