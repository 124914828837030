import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Logger, AuthService } from 'app/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IntermediateStepResolver } from '../intermediates/intermediate-step-resolver.service';
import { MatStepper } from '@angular/material/stepper';
import { IntermediateStepComponent } from '../intermediates/intermediate-step.component';
import { StartShoppingComponent } from '../sign-up/start-shopping/start-shopping.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
  @ViewChild(MatStepper, { static: false }) stepper: MatStepper;
  @ViewChild(IntermediateStepComponent, { static: false }) stepComponent: IntermediateStepComponent;
  @ViewChild(StartShoppingComponent, { static: false }) finalStepComponent: StartShoppingComponent;

  public hideSignup = false;

  public get stepDescription(): string { return this.stepResolver.description; }
  public stepIndex: number = 0;

  private redirectUrl?: string;
  public promo?: string;
  public fundraiserId?: string;
  public teamId?: string;
  public inviteId?: string;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logger: Logger,
    private auth: AuthService,
    private stepResolver: IntermediateStepResolver) { }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.redirectUrl = params.redirectUrl;
        this.promo = params.promo || undefined;
        this.fundraiserId = params.fundraiserId || undefined;
        this.teamId = params.teamId;
        this.inviteId = params.inviteId;

        if (!params.inviteId && !params.promo && !params.fundraiserId && !params.teamId) {
          this.hideSignup = true;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showStepper() {
    return this.promo || this.fundraiserId || this.teamId
  }

  public goToNextStep(): void {
    if (this.stepper) {
      this.stepper.selected.completed = true;
      this.stepper.next();

      this.stepIndex = this.stepper.selectedIndex;

      // This assumes there is only 3 steps.
      if (this.stepIndex === 1) this.stepComponent.onStepSelect();
      if (this.stepIndex === 2) this.finalStepComponent.onStepSelect();
    } else if (this.redirectUrl) {
      this.router.navigateByUrl(this.redirectUrl);
    } else {
      this.router.navigate(['/account']);
    }
  }
}
