import * as objUtil from '../object/object.util';
import * as strUtil from '../string/string.util';

/**
* Parse an HttpError from ASP.NET Web API 2, converting it to a string array.
* @param error - Error to parse.
* @returns Array of all Message or ModelState errors, or an empty array if none are found.
*/
export function parseError(error: any): string[] {
  if (!error) return [];
  if (Array.isArray(error)) {
    let errors = [];

    error.forEach(errInArr => {
      const parsedErrors = parseError(errInArr);
      if (!objUtil.isNullOrEmpty(parsedErrors)) {
        errors = errors.concat(parsedErrors);
      }
    });

    return errors;
  }

  if (error.hasOwnProperty('ModelState')) return parseModelStateError(error);
  if (error.hasOwnProperty('Message')) return [ error.Message ];
  if (error.hasOwnProperty('message')) return [ error.message ];
  if (error.hasOwnProperty('msg')) return [ error.msg ];
  if (strUtil.isString(error)) return [ error ];

  return [];
}

/**
* Parse a model state error, converting it to a string array.
*/
function parseModelStateError(error: any): string[] {
  const errors = [];

  for (const key in error.ModelState) {
    if (key === 'm') {
      errors.push('An error occurred while processing the request.');
    } else {
      for (let i = 0; i < error.ModelState[key].length; i++) {
        errors.push(error.ModelState[key][i]);
      }
    }
  }

  return errors;
}
