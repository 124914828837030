import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-chip'
  }
})
export class ChipComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }

}
