import * as util from '../string/string.util';

/**
* Deep clone an object to prevent its properties from being overwritten.
* @param obj - Object to clone.
* @returns A deep clone of the object.
*/
export function deepClone<T>(obj: T): T {
  const origObj: any = obj;
  const clone: any = {};

  for (const key in origObj) {
    if (origObj.hasOwnProperty(key)) {
      switch (Object.prototype.toString.call(origObj[key])) {
        case '[object Object]':
          clone[key] = deepClone(origObj[key]);
          break;
        case '[object Array]':
          clone[key] = [];

          origObj[key].forEach(objInArray => {
            clone[key].push(deepClone(objInArray));
          });

          break;
        case '[object Date]':
          clone[key] = new Date(origObj[key]);
          break;
        default:
          clone[key] = origObj[key];
      }
    }
  }

  return clone;
}

/**
* Check if an value is null or undefined.
* @returns Whether or not the value is null or undefined.
*/
export function isNull(value: any): value is null | undefined {
  return (value === null || value === undefined);
}

/**
* Check if an value is null, undefined, or an empty string or array.
* @returns Whether or not the value is null, undefined, or an empty string or array.
*/
export function isNullOrEmpty(value: any): value is null | undefined | '' | [] {
  return (value === null || value === undefined ||
    (util.isString(value) && value === '') || (Array.isArray(value) && value.length === 0));
}

/**
 * Remove any top-level null or undefined properties from an object.
 * @returns The object with any top-level null or undefined properties removed.
 */
export function withoutNullProperties(obj: {}): {} {
  Object.keys(obj).forEach(property => {
    if (obj[property] == null) {
      delete obj[property];
    }
  });

  return obj;
}

/**
 * Convert a value to a boolean.
 * @returns Only returns null if the value was null.
 */
export function toBoolean(value: any): boolean | null {
  if (value == null || value === '') return null;
  if (typeof value === 'boolean') return value;
  return ((util.isString(value) && value.toLowerCase() === 'true') || value === 1);
}
