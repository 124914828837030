<ng-container [ngSwitch]="appearance">
  <ng-container *ngSwitchCase="'submenu'">
    <button *ngIf="!accountMenu?.isAuthenticated; else authenticatedMenu" mat-menu-item routerLink="/login">Login</button>

    <ng-template #authenticatedMenu>
      <button mat-menu-item [matMenuTriggerFor]="accountMatMenu">{{accountMenu.displayName}}</button>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="'hover'">
    <a *ngIf="!accountMenu?.isAuthenticated; else authenticatedMenu" mat-stroked-button
       class="account-menu-btn" routerLink="/login">
      Login
    </a>

    <ng-template #authenticatedMenu>
      <div class="hover-menu">
        <ng-container *ngTemplateOutlet="accountMenuBtn; context: { path: '/account' }"></ng-container>

        <div role="menu" class="mat-menu-panel header-menu-panel hover-menu-panel hover-menu-align-right">
          <div class="mat-menu-content">
            <ng-container *ngTemplateOutlet="accountMenuContent"></ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="!accountMenu?.isAuthenticated; else authenticatedMenu">
      <a mat-stroked-button class="account-menu-btn account-menu-btn-desktop" routerLink="/login">
        Login
      </a>

      <div class="account-menu-btn-container account-menu-btn-mobile" [matMenuTriggerFor]="accountMatMenu">
        <ng-container *ngTemplateOutlet="accountMenuBtnMobile"></ng-container>
      </div>
    </ng-container>

    <ng-template #authenticatedMenu>
      <div class="account-menu-btn-container" [matMenuTriggerFor]="accountMatMenu">
        <ng-container *ngTemplateOutlet="accountMenuBtn"></ng-container>
      </div>
    </ng-template>
  </ng-container>
</ng-container>

<mat-menu #accountMatMenu="matMenu" xPosition="before" class="header-menu-panel">
  <ng-container *ngTemplateOutlet="accountMenuContent"></ng-container>
</mat-menu>

<ng-template #accountMenuContent>
  <div class="menu-section" *ngIf="!accountMenu?.isAuthenticated; else authenticatedContent">
    <a mat-menu-item routerLink="/login" routerLinkActive="menu-item-active">
      Login
    </a>
  </div>

  <ng-template #authenticatedContent>
    <div *ngFor="let routeSection of accountMenu.routes.header; index as i" class="menu-section">
      <div class="menu-divider"></div>

      <a mat-menu-item *ngFor="let route of routeSection" [routerLink]="route.path"
         routerLinkActive="menu-item-active">
        {{route.title}}
      </a>
    </div>
  </ng-template>
</ng-template>

<ng-template #accountMenuBtn let-path="path">
  <ng-container *ngIf="path">
    <a mat-stroked-button class="account-menu-btn account-menu-btn-desktop menu-trigger" [routerLink]="path">
      <ng-container *ngTemplateOutlet="accountMenuBtnDesktopInner"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngIf="!path">
    <a mat-stroked-button class="account-menu-btn account-menu-btn-desktop menu-trigger">
      <ng-container *ngTemplateOutlet="accountMenuBtnDesktopInner"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngTemplateOutlet="accountMenuBtnMobile"></ng-container>
</ng-template>

<ng-template #accountMenuBtnMobile>
  <a mat-stroked-button class="account-menu-btn account-menu-btn-mobile menu-trigger">
    <span class="user-icon">
      <i class="fas fa-user user-icon-element"></i>
    </span>
  </a>
</ng-template>

<ng-template #accountMenuBtnDesktopInner>
  <span class="display-name">
    <span class="menu-trigger-title">{{accountMenu.displayName}}</span>
  </span>
</ng-template>
