import { Pipe, PipeTransform } from '@angular/core';
import { normalizePhoneNumber, NormalizePhoneNumberOptions } from '../util';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: string, options?: NormalizePhoneNumberOptions): string {
    return normalizePhoneNumber(value, options);
  }
}
