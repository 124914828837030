import { Pipe, PipeTransform } from '@angular/core';
import { isNull } from '../util';

@Pipe({
  name: 'hostname'
})
export class HostnamePipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (isNull(value) || value === '') return '';

    try {
      const domain = new URL(value);
      return domain.hostname?.replace(/^www\./, '') || null;
    } catch (err) {
      return value;
    }
  }
}
