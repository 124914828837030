export interface GetStatementsOptions {
  recruitee?: string;
  communityId?: string;
  fundraiserId?: string;

  /**
   * Limit the result set to only statements with these reference types.
   */
  referenceTypes?: StatementReferenceType[];
}

export interface GetStatementsForPeriodOptions extends GetStatementsOptions {
  period: Date;
}

export interface StatementPeriodRange {
  /**
   * The date of the earliest period that a statement is available.
   */
  earliestPeriod?: string;

  /**
   * The date of the latest period that a statement is available.
   */
  latestPeriod?: string;
}

export interface StatementPeriods {
  /**
   * The statment periods
   */
  periods?: string[];
}

export interface IStatements {
  preGenerated: Statement[];
  onDemand: StatementType[];
}

export enum StatementReferenceType {
  FUNDRAISER = 1,
  COMMUNITY,
  MEMBER,
  TEAM
}

export interface StatementType {
  id: number;
  name: string;
  description: string;
  displayName: string;
  displaySequence?: number;

  /**
   * The required {@link Permission.name}, if any, that a user must have to request statements of this type.
   */
  permissionName?: string;

  referenceType?: {
    id: number | StatementReferenceType;
    name: string;
  };

  /**
   * Whether statements of this type are pre-generated or generated on-demand.
   */
  preGenerated: boolean;

  /**
   * Whether a user can request and download statements of this type.
   */
  requestable: boolean;
}

export interface Statement {
  id: number;
  displayName: string;
  filename: string;
  dateFrom: number;
  dateTo: number;
  fundraiserId?: string;

  statementType: StatementType;

  /**
   * Depending on the statement type, either the community or member that "owns" the statement.
   */
  owningParty: {
    id: number;

    /**
     * The community id or rise user id.
     */
    identifier: string;

    name: string;
  };
}

export interface SelectedStatements<T> {
  /**
   * The {@link Statement#id}s to download.
   */
  preGenerated: T;

  /**
   * The {@link StatementType#id}s to download.
   */
  onDemand: T;
}

export interface DownloadStatementsOptions {
  period?: Date;
  statements: SelectedStatements<number[]>;

  references?: {
    communityId: string;
    fundraiserId: string;
  };

  /**
   * Member id of sales rep to pull statements for, if the caller is a recruiter of the sales rep.
   */
  recruitee?: string;
}
