import {
  Component,
  ComponentFactoryResolver, ComponentRef,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { IIntermediateStepComponent } from './intermediate-step.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IntermediateStepResolver } from './intermediate-step-resolver.service';

@Component({
  selector: 'app-intermediate-step',
  templateUrl: './intermediate-step.component.html',
  styleUrls: ['./intermediate-step.component.scss']
})
export class IntermediateStepComponent implements IIntermediateStepComponent, OnInit, OnDestroy {
  @Output() success = new EventEmitter<void>();
  @Input() options: any;

  @ViewChild('stepContainer', { read: ViewContainerRef, static: true }) stepContainerRef: ViewContainerRef;

  private componentRef: ComponentRef<IIntermediateStepComponent> = null;
  private destroy$ = new Subject<void>();

  constructor(private resolver: ComponentFactoryResolver,
              private stepResolver: IntermediateStepResolver) { }

  ngOnInit(): void {
    this.resolveStep();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onStepSelect(): void {
    if (this.componentRef && this.componentRef.instance.onStepSelect) {
      this.componentRef.instance.onStepSelect();
    }
  }

  public resolveStep(): void {
    this.stepContainerRef.clear();

    const stepComponentType = this.stepResolver.componentType;

    const componentFactory = this.resolver.resolveComponentFactory(stepComponentType);
    this.componentRef = this.stepContainerRef.createComponent(componentFactory);
    this.componentRef.instance.options = this.options;
    this.componentRef.instance.success
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.success.emit());
  }
}
