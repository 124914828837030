/**
 * Name format for teams to use when creating teams under a fundraiser.
 */
export enum TeamNameFormatType {
  CLASSROOM = 1,
  GENERAL,
  SCOUT,
  TROOP,
  MINISTRY
}
