import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { Fundraiser } from 'app/shared/models';
import { Logger, LegacyTeamService, LegacyFundraiserService } from 'app/core';
import { finalize } from 'rxjs/operators';
import { Team } from 'app/shared/models/team.model';
import { IIntermediateStepComponent } from '../intermediate-step.model';
import { forkJoin } from 'rxjs';
import { AllocationService } from 'app/modules/allocation/allocation.service';

interface AddFundraisersOptions {
  searchQuery?: string;
  fundraiserId?: string;
  teamId?: string;
}

@Component({
  selector: 'app-add-fundraisers-step',
  templateUrl: './add-fundraisers-step.component.html',
  styleUrls: ['./add-fundraisers-step.component.scss']
})
export class AddFundraisersStepComponent implements IIntermediateStepComponent, OnInit {
  @Output() success: EventEmitter<void> = new EventEmitter();

  @Input() set options(options: AddFundraisersOptions) {
    this.searchQuery = options.searchQuery;
    this.fundraiserId = options.fundraiserId;
    this.teamId = options.teamId;
  }

  public searchQuery?: string;
  public fundraiserId?: string;
  public teamId?: string;

  public isValidating = false;
  public loadingSearch = false;
  public loadingTeams = false;
  public loadingAdd = false;
  public fundraisers: Array<Fundraiser> = [];
  // In v3 of the api, teams and fundraisers are the same thing. This will need to be updated for v4.
  public teams: Array<Team | Fundraiser> = [];
  public selectedParentFundraiser: Fundraiser = null;

  /**
   * The fundraiser to be added as an allocation.
   */
  public selectedFundraiser: Team | Fundraiser = null;

  public errorMessage: string;

  /**
   * Whether the step will be automatically adding the allocation without any user interaction required.
   */
  public isAutomaticallyAddingAllocation = false;

  /**
   * Promo code used to display the default fundraisers a user sees when entering the screen or has a blank query.
   */
  private readonly DEFAULT_PROMO_CODE = 'ZZZ9999';

  constructor(private fundraiserService: LegacyFundraiserService,
              private teamService: LegacyTeamService,
              private logger: Logger,
              private allocService: AllocationService) { }

  ngOnInit() {
    this.reset();

    if (this.teamId) {
      this.initTeam();
    } else if (this.fundraiserId) {
      this.initFundraiser();
    } else {
      this.searchFundraisers();
    }
  }

  public onStepSelect(): void {
    if (this.isAutomaticallyAddingAllocation) {
      this.addAllocations();
    }
  }

  public reset() {
    this.isAutomaticallyAddingAllocation = false;
    this.selectedParentFundraiser = null;
    this.teams = [];
    this.fundraisers = [];
  }

  public searchFundraisers(): void {
    this.reset();
    this.loadingSearch = true;

    this.fundraiserService.searchFundraisers(this.searchQuery || this.DEFAULT_PROMO_CODE, 15)
      .pipe(finalize(() => this.loadingSearch = false))
      .subscribe(
        fundraisers => {
          this.fundraisers = fundraisers;
        },
        async err => {
          this.logger.error(`Error searching fundraisers with search query "${this.searchQuery}":`, err);
          this.fundraisers = [];
        }
      );
  }

  public selectFundraiser(fundraiser: Fundraiser, teamsCache?: Team[]) {
    this.selectedFundraiser = null;
    this.selectedParentFundraiser = fundraiser;

    if (teamsCache) {
      this.setSelectedFundraiserWithTeams(fundraiser, teamsCache);
      return;
    }

    this.loadingTeams = true;
    this.teamService.getTeamsForFundraiser(fundraiser.id)
      .pipe(finalize(() => this.loadingTeams = false))
      .subscribe(teams => this.setSelectedFundraiserWithTeams(fundraiser, teams));
  }

  public selectTeam(team: Fundraiser) {
    this.selectedFundraiser = team;
  }

  public backToFundraisers() {
    const selectedParentFundraiser = this.selectedParentFundraiser;
    this.selectedParentFundraiser = null;
    this.selectedFundraiser = null;
    this.teams = [];

    // Will only happen when coming in with a teamId, in which case there would be no fundraisers to fall back to.
    if (!selectedParentFundraiser) {
      this.searchFundraisers();
    }
  }

  public addAllocations() {
    if (!this.selectedFundraiser) return;

    this.loadingAdd = true;
    const team = !!this.selectedFundraiser.fundraiserId;

    this.allocService.addAllocation({
      fundraiserId: team ? this.selectedFundraiser.fundraiserId : this.selectedFundraiser.id,
      communityId: this.selectedFundraiser.communityId,
      searchTerm: this.searchQuery,
      teamId: team ? this.selectedFundraiser.id : undefined
    })
      .pipe(finalize(() => this.loadingAdd = false))
      .subscribe(() => {
        this.success.emit();
      }, err => {
        this.errorMessage = err;
      });
  }

  /**
   * Initialize the passed-in team and pre-select it. When {@link onStepSelect} is called, the team will be
   * automatically added to the user's allocations.
   */
  private initTeam(): void {
    this.loadingSearch = true;

    this.fundraiserService.getFundraiser(this.teamId)
      .pipe(finalize(() => {
        this.loadingSearch = false;
        this.fundraisers = [];
      }))
      .subscribe(
        team => {
          this.teams = [ team ];
          this.selectTeam(team);
          this.isAutomaticallyAddingAllocation = true;
        },
        err => {
          this.logger.error(`Error searching for team "${this.teamId}"`, err);
          this.teams = [];
        }
      );
  }

  /**
   * Initialize the passed-in fundraiser and pre-select it. When {@link onStepSelect} is called, the fundraiser will be
   * automatically added to the user's allocations if it has no teams.
   */
  private initFundraiser(): void {
    this.loadingSearch = true;

    forkJoin({
      fundraiser: this.fundraiserService.getFundraiser(this.fundraiserId),
      teams: this.teamService.getTeamsForFundraiser(this.fundraiserId)
    })
      .pipe(finalize(() => this.loadingSearch = false))
      .subscribe(results => {
        const teams = results.teams || [];
        this.fundraisers = [ results.fundraiser ];
        this.selectFundraiser(results.fundraiser, teams);

        // We will only automatically add the fundraiser if it has no teams.
        this.isAutomaticallyAddingAllocation = (teams.length === 0);
      }, err => {
        this.logger.error(`Error searching for fundraiser "${this.fundraiserId}"`, err);
        this.fundraisers = [];
      });
  }

  private setSelectedFundraiserWithTeams(fundraiser: Fundraiser, teams?: Team[]): void {
    if (teams && teams.length) {
      this.teams = teams;
      this.selectedFundraiser = null;
      this.selectedParentFundraiser = fundraiser;
    } else {
      this.teams = [];
      this.selectedFundraiser = fundraiser;
      this.selectedParentFundraiser = null;
    }
  }
}
