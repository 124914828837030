import { EventEmitter, Input, OnInit, Output, Directive } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Logger, LegacyTeamService } from '../../../core';
import { IIntermediateStepComponent } from './intermediate-step.model';

@Directive()
export abstract class CreateTeamStepComponentBase implements IIntermediateStepComponent, OnInit {
  @Output() success = new EventEmitter<void>();
  @Input() options: { fundraiserId: string };

  public isSubmitting = false;

  public alert: { type: string, msg: string } = {
    type: 'success',
    msg: null
  };

  public abstract get teamName(): string;

  protected constructor(protected teamService: LegacyTeamService,
                        protected logger: Logger) { }

  ngOnInit(): void {
  }

  public createMyTeam(): void {
    this.isSubmitting = true;
    this.alert = null;

    this.teamService.createMyTeam(this.options.fundraiserId, this.teamName)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        () => {
          this.success.emit();
        },
        err => {
          this.logger.error('Error creating team:', err);
          this.alert = {
            type: 'danger',
            msg: err || 'There was an issue creating your team. Please try again.'
          };
        }
      );
  }
}
