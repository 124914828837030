<mat-dialog-content>
  <div class="text-center">
    You've already created teams under this fundraiser. Select from the list below or confirm you want to create a new team.
  </div>

  <div class="owned-teams-container">
    <div class="owned-team" *ngFor="let team of data.ownedTeams" (click)="closeDialog(false, team)">
      <div class="app-item-header">
        <div class="app-item-title">
          {{team.name}}
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="btn btn-primary" mat-flat-button color="primary" (click)="closeDialog(true)">
    Create a Team
  </button>

  <button class="btn btn-default" mat-flat-button (click)="closeDialog(false)">
    Cancel
  </button>
</mat-dialog-actions>
