import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

export enum BrowserName {
  IE = 'ie',
  EDGE = 'edge',
  FIREFOX = 'firefox',
  SAFARI = 'safari',
  CHROME = 'chrome',
  OPERA = 'opera',
  UNKNOWN = 'unknown'
}

@Injectable({
  providedIn: 'root'
})
export class BrowserDetectService {
  private readonly MAX_WIDTH_TABLET = 1024;

  constructor(private platform: Platform) { }

  /**
   * Attempt to detect a mobile browser without relying heavily on user agent (since its always changing for mobile).
   */
  public get isMobile(): boolean {
    const maxTouchPoints = window.navigator.maxTouchPoints || (window as any).navigator.msMaxTouchPoints;
    return (maxTouchPoints > 0 && window.innerWidth <= this.MAX_WIDTH_TABLET) || this.platform.IOS || this.platform.ANDROID;
  }

  public get browserName(): BrowserName {
    const agent = window.navigator.userAgent;

    if (this.platform.isBrowser) {
      // Opt for using Angular's built-in platform checks when we can.
      if (this.platform.TRIDENT) return BrowserName.IE;
      if (this.platform.EDGE) return BrowserName.EDGE;
      if (this.platform.FIREFOX) return BrowserName.FIREFOX;
      if (this.platform.SAFARI) return BrowserName.SAFARI;
      if (/chrome/i.test(agent) && ('chrome' in window)) return BrowserName.CHROME;
      if (/opr/i.test(agent) && ('opr' in window)) return BrowserName.OPERA;
    }

    return BrowserName.UNKNOWN;
  }
}
