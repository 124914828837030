export type AlertType = 'success' | 'danger' | 'warn' | 'info';

export interface IAlert {
  type: AlertType,
  msg: string;
}

export class Alert implements IAlert {
  constructor(public msg: string, public type: AlertType = 'danger') {}

  public clear(): void {
    this.msg = null;
  }
}
