import { Sort as MaterialSort } from '@angular/material/sort';

export type SortDirection = 'asc' | 'desc';

export class Sort {
  key: string;
  direction: SortDirection;

  constructor(key: string, direction: SortDirection) {
    this.key = key;
    this.direction = direction;
  }

  /**
   * Parse {@link Sort} from a string in the format of "$KEY:$DIRECTION"
   * @param {string} value
   * @returns {Sort | null} Null if the string could not be parsed.
   */
  public static fromString(value: string): Sort {
    if (!value) return null;

    const re = /^(?<key>\w+):(?<direction>asc|desc)$/i;
    const match = value.match(re);
    if (!match) return null;
    return new Sort(match.groups.key, match.groups.direction as SortDirection);
  }

  public static fromMaterialSort(value: MaterialSort): Sort {
    if (!value || !value.direction) return null;
    return new Sort(value.active, value.direction);
  }

  public toString(): string {
    return this.key ? `${this.key}:${this.direction}` : null;
  }
}
