export interface PartnerProvider {
  id: string;
  name: string;
}

export interface IMemberPPSetting {
  key: string;
  value: string | number;
  provider: PartnerProvider;
}

export class MemberPPSetting implements IMemberPPSetting {
  key: string;
  value: string;
  provider: PartnerProvider;

  constructor(key, value, provider) {
    this.key = key;
    this.value = value;
    this.provider = provider;
  }
}

export interface MemberPPSettingDao {
  id: string
  ppId: string
  ppName?: string;
  memberId: string
  key: string
  value: string
  createdAt: Date
  updatedAt: Date
}

export interface UpsertMemberPPSetting {
  key: string;
  value: string | number;
  ppId: string;
  memberId: string;
}
