<form class="form-group input-search" (ngSubmit)="search.emit()">
  <input type="search" class="form-control" name="search" [placeholder]="placeholder"
         [(ngModel)]="value" [disabled]="isDisabled" />

  <div class="input-search-actions">
    <div class="input-search-clear" *ngIf="value" (click)="clearValue()">
      ×
    </div>

    <button type="submit" class="btn input-search-submit">
      <i class="fas fa-search"></i>
    </button>
  </div>
</form>
