import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth';
import { Logger } from '../logger.service';
import { DialogService, NavigationService } from '../services';

@Injectable()
export class TransferTokenGuard implements CanActivate {
  constructor(private auth: AuthService,
              private dialog: DialogService,
              private location: Location,
              private nav: NavigationService,
              private logger: Logger) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // token and transferToken are valid to prevent any mixup with usage
    const transferToken = route.queryParams.token || route.queryParams.transferToken;
    return this.isAuthenticated()
      .pipe(switchMap(isAuth => {
        if (!transferToken) {
          if (isAuth) return of(true);
          else {
            throw new Error();
          }
        }
        return of(false);
      }), switchMap(isAuth => {
        if (isAuth) return of(isAuth);
        return this.auth.tokenTransferLogin(transferToken).pipe(switchMap(() => of(true)));
      }), catchError(err => {
        this.logger.error(err);
        this.showNotAuthenticatedDialog();
        return of(false);
      }))
  }

  isAuthenticated() {
    return this.auth.isAuthenticated()
  }

  showNotAuthenticatedDialog() {
    this.dialog.show('Error', 'Error authenticating.', [{
      text: 'Ok',
      click: () => this.nav.goBack()
    }])
  }
}
