import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { HttpService, IEarnResponse, IRequestOptions } from '../../http';
import { GetTeamIncludeType, Team } from '../../../shared/models/team.model';
import { withoutNullProperties } from '../../../shared/util';
import { CacheService } from '../utility/cache.service';

interface GetTeamsForFundraiserOptions {
  exclude: ('claimed')[];
  include: GetTeamIncludeType[];
}

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  private cacheKeys = {
    teamsByFundraiser: 'getTeamsByFundraiser'
  };

  constructor(private http: HttpService,
              private cache: CacheService) { }

  public getTeams(searchQuery: string, limit: number | string = 10, offset: number | string = 0, includeTeams: boolean = false,
                  name?: string, parentFundraiser?: string): Observable<Team[]> {
    const options = {
      params: withoutNullProperties({
        search: searchQuery,
        limit: limit,
        offset: offset,
        name: name,
        includeTeams: includeTeams,
        fundraiserId: parentFundraiser
      })
    };

    return this.http.get<IEarnResponse & { teams: Team[] }>('/team', options)
      .pipe(map(res => {
        return res.teams;
      }));
  }

  public getTeam(id: string) {
    return this.http.get<IEarnResponse & { team: Team }>(`/team/${id}`)
      .pipe(map(res => res.team), first());
  }

  public getTeamsForFundraiser(fundraiserId: string, options: Partial<GetTeamsForFundraiserOptions> = {}): Observable<Team[]> {
    const reqOptions: IRequestOptions = {
      params: withoutNullProperties({
        exclude: options.exclude && options.exclude.join(','),
        include: options.include && options.include.join(','),
        includeFundraiserDetails: true
      })
    };

    const req$ = this.http.get<IEarnResponse & { teams: Team[] }>(`/fundraiser/${fundraiserId}/team`, reqOptions)
      .pipe(map(res => res.teams));

    const cacheOptions = { ttl: 10 * 60 * 1000, args: { fundraiserId, options } };
    return this.cache.setIfNotExists(this.cacheKeys.teamsByFundraiser, req$, cacheOptions);
  }

  public claimTeam(teamId: string): Observable<void> {
    return this.http.post(`/team/${teamId}/claim`);
  }
}
