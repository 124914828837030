import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Logger, UtilityService } from '../../../core';

export abstract class EditFormBaseComponent<T> {
  public alert: { type: string, msg: string } = { type: 'danger', msg: null };

  public form: UntypedFormGroup;
  public isSavingEdit = false;

  protected abstract get save$(): Observable<any>;

  constructor(protected util: UtilityService,
              protected formBuilder: UntypedFormBuilder,
              protected logger: Logger) {
    this.form = this.buildForm();

    // Editing is disabled by default.
    this.form.disable();
  }

  public hasError(ctrlName: string, errorName?: string): boolean {
    return this.util.hasError(this.form, ctrlName, errorName);
  }

  public cancelEdit(): void {
    this.copyValuesToForm();
    this.form.disable();
  }

  public startEdit(): void {
    this.form.enable();
  }

  public saveEdit(): void {
    this.isSavingEdit = true;
    this.alert = null;

    this.form.disable();

    this.save$
      .pipe(finalize(() => this.isSavingEdit = false))
      .subscribe(
        () => {
          /** Do nothing. Success should be handled in {@link save$}. */
        },
        err => {
          this.logger.error(err);
          this.alert = { type: 'danger', msg: err };
        }
      );
  }

  protected abstract buildForm(): UntypedFormGroup;
  protected abstract copyValuesToForm(): void;
  protected abstract getValuesFromForm(): T;
}
