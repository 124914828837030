import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { Logger } from '../../logger.service';
import { GoogleService } from '../google/google.service';

export class State {
  constructor(public name: string, public abbr: string) {}
}

@Injectable()
export class FormService {
  public readonly states: State[] = [
    new State('Alabama', 'AL'),
    new State('Alaska', 'AK'),
    new State('American Samoa', 'AS'),
    new State('Arizona', 'AZ'),
    new State('Arkansas', 'AR'),
    new State('California', 'CA'),
    new State('Colorado', 'CO'),
    new State('Connecticut', 'CT'),
    new State('Delaware', 'DE'),
    new State('District Of Columbia', 'DC'),
    new State('Federated States Of Micronesia', 'FM'),
    new State('Florida', 'FL'),
    new State('Georgia', 'GA'),
    new State('Guam', 'GU'),
    new State('Hawaii', 'HI'),
    new State('Idaho', 'ID'),
    new State('Illinois', 'IL'),
    new State('Indiana', 'IN'),
    new State('Iowa', 'IA'),
    new State('Kansas', 'KS'),
    new State('Kentucky', 'KY'),
    new State('Louisiana', 'LA'),
    new State('Maine', 'ME'),
    new State('Marshall Islands', 'MH'),
    new State('Maryland', 'MD'),
    new State('Massachusetts', 'MA'),
    new State('Michigan', 'MI'),
    new State('Minnesota', 'MN'),
    new State('Mississippi', 'MS'),
    new State('Missouri', 'MO'),
    new State('Montana', 'MT'),
    new State('Nebraska', 'NE'),
    new State('Nevada', 'NV'),
    new State('New Hampshire', 'NH'),
    new State('New Jersey', 'NJ'),
    new State('New Mexico', 'NM'),
    new State('New York', 'NY'),
    new State('North Carolina', 'NC'),
    new State('North Dakota', 'ND'),
    new State('Northern Mariana Islands', 'MP'),
    new State('Ohio', 'OH'),
    new State('Oklahoma', 'OK'),
    new State('Oregon', 'OR'),
    new State('Palau', 'PW'),
    new State('Pennsylvania', 'PA'),
    new State('Puerto Rico', 'PR'),
    new State('Rhode Island', 'RI'),
    new State('South Carolina', 'SC'),
    new State('South Dakota', 'SD'),
    new State('Tennessee', 'TN'),
    new State('Texas', 'TX'),
    new State('Utah', 'UT'),
    new State('Vermont', 'VT'),
    new State('Virgin Islands', 'VI'),
    new State('Virginia', 'VA'),
    new State('Washington', 'WA'),
    new State('West Virginia', 'WV'),
    new State('Wisconsin', 'WI'),
    new State('Wyoming', 'WY')
  ];

  public readonly passwordValidation = {
    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,25}$/,
    error: 'Password must contain at least one upper and lower case letter, one number, and be between 6 and 25 characters long.'
  };

  public readonly zipCodeValidation = {
    pattern: /^\d{5}(?:-?\d{4})?$/,
    error: `Zip code is not a valid zip code.`
  }

  constructor(private google: GoogleService, private logger: Logger) {}

  addZipCodeValidation(form: NgForm) {
    return form.controls['zipCode'].valueChanges
      .pipe(
        distinctUntilChanged(),
        filter(() => form.controls['zipCode'].valid),
        switchMap(newZipCode => {
          form.controls['city'].disable();
          form.controls['state'].disable();
          form.controls['zipCode'].setErrors({zipValidating: true})
          return this.google.getZipPrimary(newZipCode).pipe(catchError(err => {
            this.logger.error(err);
            return of(null);
          }))
        })
      )
      .subscribe(
        cityState => {
          if (cityState) {
            form.controls['city'].setValue(cityState.city);
            form.controls['state'].setValue(cityState.state);
            form.controls['zipCode'].setErrors(null)
          } else {
            form.controls['zipCode'].setErrors({invalidZip: true})
          }
          form.controls['city'].enable();
          form.controls['state'].enable();
        }, err => {
          this.logger.error(err);
        });
  }
}
