import { AfterViewInit, Directive, ElementRef, HostListener, NgZone } from '@angular/core';

/**
 * Multi-line text truncation (split by spaces) using an element's max-height.
 * Falls back to element height if max-height is not available.
 *
 * Does NOT support changes to the text after initialization.
 */
@Directive({
  selector: '[appTruncate]'
})
export class TruncateDirective implements AfterViewInit {
  private ellipsisChar = '…';
  private maxHeight: number;

  /**
   * Used to re-truncate text on window resize.
   */
  private origText: string;

  private get hasOverflow(): boolean {
    return this.maxHeight < this.elementRef.nativeElement.scrollHeight;
  }

  constructor(private elementRef: ElementRef,
              private ngZone: NgZone) { }

  @HostListener('window:resize')
  private onResize(): void {
    this.ngZone.run(() => {
      this.truncate();
    });
  }

  ngAfterViewInit() {
    this.origText = this.elementRef.nativeElement.innerText;
    this.truncate();
  }

  private truncate(): void {
    const el: HTMLElement = this.elementRef.nativeElement;
    this.calcMaxHeight();

    // Overflow must be hidden to calculate if element has overflow.
    const origOverflow = el.style.overflow;
    el.style.overflow = 'hidden';

    el.innerText = this.origText;
    let words = this.origText.split(' ');
    while (this.hasOverflow && words.length > 0) {
      words = words.slice(0, -1);
      el.innerText = words.join(' ') + this.ellipsisChar;
    }

    el.style.overflow = origOverflow;
  }

  private calcMaxHeight(): void {
    const el: HTMLElement = this.elementRef.nativeElement;
    const styles = getComputedStyle(el);

    let maxHeight = this.toNumber(styles.maxHeight);

    if (isNaN(maxHeight)) {
      maxHeight = el.offsetHeight;
    }

    this.maxHeight = maxHeight;

    if (!el.style.maxHeight) {
      el.style.maxHeight = maxHeight + 'px';
    }
  }

  private toNumber(value: string): number {
    return parseFloat(value.replace(/[^0-9\-.]/g, ''));
  }
}
