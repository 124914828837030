import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { LegacyTeamService, Logger } from '../../../core';
import { DialogService } from '../../../core/services/dialog/dialog.service';

@Component({
  selector: 'app-create-my-team-dialog',
  templateUrl: './create-my-team-dialog.component.html',
  styleUrls: ['./create-my-team-dialog.component.scss']
})
export class CreateMyTeamDialogComponent implements OnInit {
  public readonly websiteUrl = environment.huterra.website.url;

  public isLoading = false;

  public teamName: string;

  public alert: { type: string, msg: string } = {
    type: 'success',
    msg: null
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: { fundraiserId: string, fundsTeamSplit?: number },
              private dialogRef: MatDialogRef<CreateMyTeamDialogComponent>,
              private dialogService: DialogService,
              private teamService: LegacyTeamService,
              private logger: Logger) { }

  ngOnInit() {}

  public createMyTeam(): void {
    this.isLoading = true;

    this.teamService.createMyTeam(this.data.fundraiserId, this.teamName)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        async () => {
          await this.dialogRef.close({ teamCreated: true});
        },
        err => {
          this.logger.error('Error creating team:', err);
          this.alert = {
            type: 'danger',
            msg: err || 'There was an issue creating your team. Please try again.'
          };
        }
      );
  }

  public displayNameIdeas(): void {
    this.dialogService.show(
      null,
      `If you are setting up a team for your child, you may want to use their last name and first initial (such as "Smith, J").`
      + ` If you are setting up a team for a classroom, you may want to use your title, last name, class, school and city (such as "Mrs. Johnson 3rd Grade, Kennedy Elem Chicago, IL").`,
      [{ text: 'OK', color: 'primary' }],
      {
        panelClass: ['text-center', 'dialog-text-small'],
        maxWidth: 400
      }
    );
  }
}
