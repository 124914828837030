import { Injectable } from "@angular/core";

@Injectable()
export class UrlService {
    private getQueryIndex(url: string) {
        return url.indexOf('?')
    }

    getQueryStringParams(url: string): {[key: string]: any} {
        let result = {}
        const queryIndex = this.getQueryIndex(url)
        if (queryIndex > -1) {
            const queryPart = url.slice(queryIndex + 1)
            const params = queryPart.split('&')
            for (let param of params) {
                let firstEqual = param.indexOf('=')
                let key = decodeURIComponent(param.slice(0, firstEqual))
                let value = decodeURIComponent(param.slice(firstEqual + 1))
                result[key] = value
            }
        }
        return result
    }

    getUrl(url: string): string {
        const queryIndex = this.getQueryIndex(url)
        if (queryIndex > -1) {
            return url.slice(0, queryIndex)
        }
        return url
    }

    getUrlParts(url: string): UrlParts {
        if (!url) return null
        return {
            url: this.getUrl(url),
            queryParams: this.getQueryStringParams(url)
        } as UrlParts
    }
}

export interface UrlParts {
    url: string
    queryParams: {[key: string]: string}
}