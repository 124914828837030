export enum UserRole {
  /** Admin */
  User = 'HuTerra2User',

  /** Super Admin */
  Admin = 'HuTerra2Admin',

  ReportExtractor = 'HuTerra2ReportExtractor',
  Association = 'HuTerra2Association',
  BankTransactionLoad = 'HuTerra2BankTransactionLoad',
  BankTransactionEditor = 'HuTerra2BankTransactionEditor',
  Batch = 'HuTerra2Batch',
  BusinessEditor = 'HuTerra2BusinessEditor',
  BusinessDelete = 'HuTerra2BusinessDelete',
  BusinessLoad = 'HuTerra2BusinessLoad',
  OrganizationView = 'HuTerra2OrgView',
  GeoCoder = 'HuTerra2GeoCoder',
  KPI = 'HuTerra2KPI',
  Role = 'HuTerra2Role',
  MemberEditor = 'HuTerra2MemberEditor'
}
