/**
 * Theme to use in the header.
 */
export enum LayoutTheme {
  /**
   * Light logo and light text. Default theme.
   */
  DARK = 'dark',

  /**
   * Normal logo and dark text, on white background.
   */
  LIGHT = 'light',

  /**
   * Dark theme with no background.
   */
  TRANSPARENT = 'transparent'
}
