<div class="header-container" [ngClass]="'theme-' + theme"
     [class.admin-header]="appearance === 'admin'"
     [class.funnel-mode]="layout.mode === LayoutMode.FUNNEL">
  <div class="container">
    <div class="row" id="app-header-full">
      <div class="col-sm-6 logo" *ngIf="!noNav">
        <ng-container *ngTemplateOutlet="sidebarToggleBtn"></ng-container>

        <a [href]="websiteUrl">
          <img [src]="logo" [alt]="brandName">
        </a>
      </div>
      <div class="col-sm-6 logo" *ngIf="noNav">
        <img [src]="logo" [alt]="brandName">
      </div>
      <div class="col-sm-6 menu" *ngIf="!noNav">
        <ul>
          <ng-container *ngIf="appearance !== 'admin'">
            <li *ngFor="let route of routes">
              <ng-container *ngIf="route.children?.length; else angularRoute">
                <div class="hover-menu">
                  <a mat-button class="header-menu-item menu-trigger" [routerLink]="route.path">
                    <span class="menu-trigger-title">{{route.title}}</span>
                  </a>

                  <div role="menu" class="mat-menu-panel header-menu-panel hover-menu-panel">
                    <div class="mat-menu-content">
                      <a mat-menu-item *ngFor="let childRoute of route.children" [routerLink]="childRoute.path">
                        {{childRoute.title}}
                      </a>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-template #angularRoute>
                <a *ngIf="!route.href; else nonAngularRoute" [routerLink]="route.path" class="header-menu-item">
                  {{route.title}}
                </a>
              </ng-template>

              <ng-template #nonAngularRoute>
                <a [href]="route.href" [target]="route.target || '_self'" class="header-menu-item">
                  {{route.title}}
                </a>
              </ng-template>
            </li>
          </ng-container>

          <li>
            <app-account-menu appearance="hover"></app-account-menu>
          </li>
        </ul>
      </div>
    </div>

    <div class="row" id="app-header-mobile">
      <div class="col-sm-6 logo" *ngIf="!noNav">
        <ng-container *ngTemplateOutlet="sidebarToggleBtn"></ng-container>

        <a [href]="websiteUrl">
          <img class="logo-full" [src]="logo" [alt]="brandName">
        </a>
      </div>
      <div class="col-sm-6 logo" *ngIf="noNav">
        <img class="logo-full" [src]="logo" [alt]="brandName">
      </div>

      <div class="col-sm-6 menu text-right" *ngIf="!noNav">
        <app-account-menu></app-account-menu>

        <button *ngIf="appearance !== 'admin'" mat-icon-button [matMenuTriggerFor]="mobileMenu"
                aria-label="Open menu" class="menu-trigger">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <mat-menu #mobileMenu="matMenu" xPosition="before" class="header-menu-panel">
    <div class="menu-section">
      <ng-container *ngFor="let route of routes">
        <ng-container *ngIf="route.children?.length; else angularRouteMobile">
          <button mat-menu-item [matMenuTriggerFor]="menu" class="header-menu-item menu-trigger">
            <span class="menu-trigger-title">{{route.title}}</span>
          </button>

          <mat-menu #menu="matMenu" class="header-menu-panel">
            <a mat-menu-item *ngFor="let childRoute of route.children" [routerLink]="childRoute.path"
               routerLinkActive="menu-item-active">
              {{childRoute.title}}
            </a>
          </mat-menu>
        </ng-container>

        <ng-template #angularRouteMobile>
          <a mat-menu-item *ngIf="!route.href; else nonAngularRouteMobile" [routerLink]="route.path" class="header-menu-item">
            {{route.title}}
          </a>
        </ng-template>

        <ng-template #nonAngularRouteMobile>
          <a mat-menu-item [href]="route.href" [target]="route.target || '_self'" class="header-menu-item">
            {{route.title}}
          </a>
        </ng-template>
      </ng-container>
    </div>
  </mat-menu>
</div>

<ng-template #sidebarToggleBtn>
  <a class="sidebar-toggle" *ngIf="appearance === 'admin'" (click)="toggleSidebar()">
    <i class="fas fa-bars"></i>
  </a>
</ng-template>
