import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Route,
  CanActivateChild,
  CanLoad,
  UrlSegment, NavigationCancel, Data
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { UserRole } from 'app/shared/models';
import { first, map } from 'rxjs/operators';

/**
 * Guards a route against users that are not logged on or do not have the required role to access a route.
 *
 * Additional properties can be added in {@link Route.data} to define specific behavior within the AuthGuard.
 *
 * ## `role`: {@link UserRole}
 * Users must have the given role to pass the guard check. No role means that the user must only be logged in.
 *
 * @example
 * ```ts
 * const route: Route = {
 *   // ...
 *   canActivate: [AuthGuard],
 *   data: { role: UserRole.Admin }
 * }
 * ```
 *
 * ## `skipSUWizardOnAuthFail`: boolean
 * Whether to skip the sign up wizard if user fails the guard check then chooses to sign up. Skipping the sign up
 * wizard means that user will be redirected after completing the first step (creating an account) of sign up,
 * instead of after all steps.
 *
 * @example
 * ```ts
 * const route: Route = {
 *   // ...
 *   canActivate: [AuthGuard],
 *   data: { skipSUWizardOnAuthFail: true }
 * }
 * ```
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      return this.isAuthorized(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      return this.canActivate(route, state);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.auth.isAuthorized(route.data.role)
      .pipe(map(isAuthorized => {
        if (isAuthorized) return true;

        /**
         * There's no nice way to get the full original url on canLoad, so we use wait for the {@link NavigationCancel}
         * event to trigger after we reject canLoad so we can get the full url.
         */
        this.router.events
          .pipe(first(event => event instanceof NavigationCancel))
          .subscribe((event: NavigationCancel) => {
            // 403 is thrown when a user is not logged in, so redirect to the login page.
            this.goToLogin(event.url, route.data);
          });

        return false;
      }));
  }

  /**
   * Check whether a user is authenticated and authorized.
   *
   * @remarks
   * If a user is not logged in, navigate to the login page with a return URL of the given URL.
   */
  private isAuthorized(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.isAuthorized(route.data.role)
      .pipe(map(isAuthorized => {
        if (isAuthorized) return true;
        this.goToLogin(state.url, route.data);
        return false;
      }));
  }

  private goToLogin(redirectUrl: string, routeData: Data): Promise<boolean> {
    const queryParams: any = { redirectUrl, unauthorized: true };
    if (routeData.skipSUWizardOnAuthFail) queryParams.skipSignUpWizard = true;

    return this.router.navigate(['login'], { queryParamsHandling: 'merge', queryParams });
  }
}
