import * as util from '../object/object.util';

/**
* Sort an object of type T according to the given property.
* @example anArray.sort(util.sortBy("property1", "property2");
* @param properties - Properties to sort by. To sort by descending, prefix the property with a "-".
* @returns Comparer function to sort with.
*/
export function sortBy<T extends {[key: string]: any}>(...properties: string[]): (objA: T, objB: T) => number {
  return (objA: T, objB: T): number => {
    let i = -1;
    while (++i < properties.length) {
      const cmpFn = sortComparer(properties[i]);
      const result = cmpFn(objA, objB);
      if (result) return result;
    }
  };
}

function sortComparer<T extends {[key: string]: any}>(property: string): (objA: T, objB: T) => number {
  if (util.isNullOrEmpty(property)) {
    return (objA: T, objB: T): number => 0;
  }

  let order = 1;
  if (property[0] === '-') {
    order = -1;
    property = property.substring(1);
  }

  const properties = property.split('.');
  const getValue = (obj: {[key: string]: any}, prop: string) => obj[prop];

  return (objA: T, objB: T): number => {
    const valA = properties.reduce(getValue, objA);
    const valB = properties.reduce(getValue, objB);

    const result = valA < valB
      ? -1
      : valA > valB
        ? 1
        : 0;

    return result * order;
  };
}

/**
 * Creates a duplicate-free version of the given array based on the value generated by the provided function.
 */
export function uniqueBy<T>(array: T[], keyGenerator: (item: T) => any): T[] {
  const map = new Map();
  const result = [];

  array.forEach(anItem => {
    const key = keyGenerator(anItem);
    if (!map.has(key)) {
      map.set(key, anItem);
      result.push(anItem);
    }
  });

  return result;
}

/**
 * Create a map that groups the given array by the key generated by the provided function.
 */
export function groupBy<TKey, TValue>(array: TValue[], keyGenerator: (item: TValue) => TKey): Map<TKey, TValue[]> {
  const map = new Map();

  array.forEach(item => {
    const key = keyGenerator(item);
    const collection = map.get(key);
    if (!collection) map.set(key, [item]);
    else collection.push(item);
  });

  return map;
}

/**
 * Sum the given numbers.
 */
export function sum(numbers: number[]): number {
  return numbers.reduce((accumulator, aNumber) => Number((accumulator + aNumber).toFixed(2)), 0);
}
