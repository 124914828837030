import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService, IEarnResponse, IRequestOptions } from '../../http';
import { isString } from '../../../shared/util';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  /**
   * Script promises loaded via {@link loadScript}.
   */
  private loadedScripts: { [url: string]: Promise<void> } = {};

  constructor(private http: HttpService) { }

  /**
   * Encrypt data using IV encryption.
   */
  public encrypt(data: any): Observable<string> {
    const options: IRequestOptions = {
      body: {
        message: isString(data) ? data : JSON.stringify(data)
      }
    };

    return this.http.post<IEarnResponse>('/auth/ivencrypt', options)
      .pipe(map(res => res.message));
  }

  /**
   * Decrypt data encrypted using {@link encrypt}.
   */
  public decrypt<T>(data: string): Observable<T> {
    const options: IRequestOptions = {
      body: { message: data }
    };

    return this.http.post<IEarnResponse>('/auth/ivdecrypt', options)
      .pipe(map(res => {
        try {
          return JSON.parse(res.message);
        } catch {
          return res.message;
        }
      }));
  }

  public async loadScript(url: string, options: { defer?: boolean, async?: boolean } = {}): Promise<void> {
    // Do not attempt to load a script twice.
    if (this.loadedScripts[url]) return this.loadedScripts[url];

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    if (options.async != null) script.async = options.async;
    if (options.defer != null) script.defer = options.defer;

    const loadedScript = this.loadedScripts[url] = new Promise<void>((resolve, reject) => {
      script.onerror = (err: any) => {
        reject(err);
        delete this.loadedScripts[url];
      };

      script.onload = () => resolve();
    });

    document.getElementsByTagName('body')[0].appendChild(script);

    return loadedScript;
  }

  /**
   * Check if a control has an error after its been touched. If no errorName is provided, checks for any error.
   */
  public hasError(formGroup: UntypedFormGroup, ctrlName: string, errorName?: string): boolean {
    if (!formGroup) return false;
    const ctrl = formGroup.get(ctrlName);
    return ctrl && ctrl.invalid && ctrl.touched && (errorName ? ctrl.hasError(errorName) : true);
  }

  /**
   * Open the My HuTerra app using deep linking. App must support the given endpoint.
   */
  public openDeepLink(endpoint: string): void {
    window.open(environment.deepLinkUrl + endpoint, '_self');
  }
}
