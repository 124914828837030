import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-download-app-cta',
  templateUrl: './download-app-cta.component.html',
  styleUrls: ['./download-app-cta.component.scss']
})
export class DownloadAppCtaComponent implements OnInit {
  public readonly appName = environment.appName;
  public readonly disableMyFundraisers = environment.brandName === '4GOODRewards';

  constructor() { }

  ngOnInit(): void {
  }
}
