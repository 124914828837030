import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IntermediateStepResolver } from '../intermediates/intermediate-step-resolver.service';
import { IntermediateStepComponent } from '../intermediates/intermediate-step.component';
import { StartShoppingComponent } from './start-shopping/start-shopping.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
  @ViewChild(MatStepper, { static: false }) stepper: MatStepper;
  @ViewChild(IntermediateStepComponent, { static: false }) stepComponent: IntermediateStepComponent;
  @ViewChild(StartShoppingComponent, { static: false }) finalStepComponent: StartShoppingComponent;

  public promo?: string;
  public fundraiserId?: string;
  public teamId?: string;
  public inviteId?: string;

  /**
   * Whether to skip the wizard and redirect user to {@link redirectUrl} after completing only the first step.
   * @see {@link AuthGuard}
   */
  public skipWizard = false;
  public redirectUrl?: string;

  public get stepDescription(): string { return this.stepResolver.description; }
  public stepIndex: number = 0;

  public get fundraiserStepDisabled() {
    return environment.brandName === '4GOODRewards' && !this.promo && !this.fundraiserId && !this.teamId;
  }

  private destroy$ = new Subject<void>();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private stepResolver: IntermediateStepResolver) {

  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.promo = params.promo || undefined;
        this.fundraiserId = params.fundraiserId || undefined;
        this.teamId = params.teamId;
        this.redirectUrl = params.redirectUrl;
        this.skipWizard = (params.skipSignUpWizard || false) && params.redirectUrl;
        this.inviteId = params.inviteId;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  next() {
    if (this.stepIndex === 0 && this.skipWizard) {
      this.router.navigateByUrl(this.redirectUrl);
      return;
    }

    this.stepper.selected.completed = true;
    this.stepper.next();

    this.stepIndex = this.stepper.selectedIndex;

    if (this.fundraiserStepDisabled) {
      // There is only two steps, so go to the final one.
      this.finalStepComponent.onStepSelect();
    } else {
      // This assumes there is only 3 steps.
      if (this.stepIndex === 1) this.stepComponent.onStepSelect();
      if (this.stepIndex === 2) this.finalStepComponent.onStepSelect();
    }
  }

  signIn() {
    this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
  }
}
