import { EventType, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

/**
 * Because {@link Window#history} isn't always reliable.
 */
@Injectable()
export class RouterHistoryService implements OnDestroy {
  /**
   * The previous URL as it would be in the browser's history. It's the URL you would go to if you clicked the back
   * button in a browser, rather than the URL the user came from.
   * @example A user follows goes to routeA, then routeB, then routeC, then clicks the back button, landing back on
   * routeB. The previousUrl at this point would be routeA, NOT routeC.
   */
  public previousUrl$ = new BehaviorSubject<string>(null);
  public currentUrl$ = new BehaviorSubject<string>(null);

  private history: RouterEvent[] = [];
  private currentIndex = 0;

  /**
   * We need {@link NavigationStart#restoredState} and {@link NavigationStart#navigationTrigger}, but we
   * only want to act once {@link NavigationEnd} has triggered, so we save the start event to use once
   * {@link NavigationEnd} is triggered.
   */
  private navStartEvent: NavigationStart = null;

  private destroy$ = new Subject<void>();

  constructor(private router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart),
            takeUntil(this.destroy$))
      .subscribe((event: NavigationStart) => this.navStartEvent = event);

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd),
            takeUntil(this.destroy$))
      .subscribe((event: NavigationEnd) => this.updateHistory(event));
  }

  public get hasHistory(): boolean {
    return !(this.previousUrl$.getValue() == null);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateHistory(event: NavigationEnd): void {
    const navigation = this.router.getCurrentNavigation();

    /**
     * {@link NavigationStart} is not triggered on initial app load. Since our logic assumes it will always be
     * there, we'll create a fake start event using the end event. After initial load, {@link navStartEvent} will
     * never be null.
     */
    if (!this.navStartEvent) {
      this.navStartEvent = {
        id: event.id,
        navigationTrigger: 'imperative',
        url: event.url,
        type: EventType.NavigationStart
      };
    }

    /**
     * Add the new event to the end of the history and set that as our current index.
     * Note: An imperative trigger is a call to router.navigateByUrl() or router.navigate().
     */
    if (this.navStartEvent.navigationTrigger === 'imperative') {
      if (navigation.extras.replaceUrl && this.history.length > 0) {
        this.history.pop();
        this.currentIndex--;
      }

      this.history.splice(this.currentIndex + 1);
      this.history.push(new RouterEvent(this.navStartEvent.id, event.urlAfterRedirects));
      this.currentIndex = this.history.length - 1;
    }

    /**
     * Update an existing item in this.history with its new navigation id. This is necessary since every navigation
     * will have a new navigation id, even if its returning to a route in history.
     * Note: A popstate trigger are browser events, such as back and forward.
     */
    if (this.navStartEvent.navigationTrigger === 'popstate') {
      const idx = this.history.findIndex(historyEvent => {
        const navId = this.navStartEvent.restoredState && this.navStartEvent.restoredState.navigationId;
        return historyEvent.id === navId;
      });

      if (idx > -1) {
        this.currentIndex = idx;
        this.history[idx].id = this.navStartEvent.id;
      } else {
        this.currentIndex = 0;
      }
    }

    const previous = this.history[this.currentIndex - 1];
    const current = this.history[this.currentIndex];

    this.previousUrl$.next(previous && previous.url);
    this.currentUrl$.next(current.url);
  }
}
