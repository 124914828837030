<mat-dialog-content class="text-center">
  <div class="description">
    <p>
      Creating a team for this fundraiser allows you to directly receive the funds for the rewards earned by your team. You will be able to use the funds to purchase gift cards or transfer them into your bank account. Funds will be available 60 days after the end of the month the funds were earned. See our <a [href]="websiteUrl + 'terms'" target="_blank">Terms of Service</a> for more details.
    </p>

    <p *ngIf="data.fundsTeamSplit">
      The funds will be split <strong>{{data.fundsTeamSplit}}%</strong> to you and {{100 - data.fundsTeamSplit}}% to the entire fundraiser, as set by the fundraiser administrator. The percent of funds to the entire team will be used at the decision of the fundraiser administrator.
    </p>
  </div>

  <h3 class="text-center">Create a Team</h3>

  <form (ngSubmit)="createMyTeam()" #createTeamForm="ngForm">
    <alert [type]="alert.type" *ngIf="alert?.msg" dismissible="true">
      {{ alert.msg }}
    </alert>

    <mat-form-field>
      <input matInput placeholder="Team Name" type="text" name="name" #name="ngModel"
             required [(ngModel)]="teamName" autofocus>
      <mat-hint align="start">We suggest you make it easy to find.</mat-hint>
      <mat-hint align="end"><a href="javascript:void(0);" (click)="displayNameIdeas()">Need ideas?</a></mat-hint>
      <mat-error *ngIf="name.invalid">Team name is required.</mat-error>
    </mat-form-field>

    <div class="form-actions-group">
      <button type="submit" class="btn btn-primary" [disabled]="!createTeamForm.valid || isLoading">
        <i class="fas fa-spinner fa-spin" *ngIf="isLoading"></i>
        <span *ngIf="!isLoading">Create a Team</span>
      </button>

      <button class="btn btn-default" [mat-dialog-close]="true">Cancel</button>
    </div>
  </form>

  <div><small>Don't forget to share your team with others to raise even more!</small></div>
</mat-dialog-content>
