import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { CsvErrorTableComponent } from './components';
import { TruncateDirective } from './directives';
import { PhonePipe } from './pipes/phone.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { HostnamePipe } from './pipes/hostname.filter';
import { InitDirective } from './directives/init/init.directive';
import { DialogComponent } from './components/dialog/dialog.component';
import { FundraiserDescriptionComponent } from './components/fundraiser-description/fundraiserDescription.component';
import { ActionCardComponent } from './components/action-card/action-card.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { AppBadgeComponent } from './components/app-badge/app-badge.component';
import { ChipComponent } from './components/chip/chip.component';
import { InputSearchComponent } from './components/input-search/input-search.component';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { MatIconModule } from '@angular/material/icon';

const components = [
  CsvErrorTableComponent,
  FilterPipe,
  PhonePipe,
  HostnamePipe,
  DialogComponent,
  FundraiserDescriptionComponent,
  ActionCardComponent,
  BackButtonComponent,
  AppBadgeComponent,
  ChipComponent,
  InputSearchComponent,
  ReadMoreComponent,
];

const directives = [
  TruncateDirective,
  InitDirective
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatTableModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ...components,
        ...directives
    ],
    declarations: [...components, ...directives],
    providers: []
})
export class SharedModule { }
