import { Injectable } from '@angular/core';
import { ICookie } from './cookie.model';
import { BusinessLocationCookie } from './cookies/business-location-cookie.service';

/**
 * Manages cookies that are dependent on user's auth state. Will automatically delete registered cookies
 * when a user is logged out.
 *
 * @remarks
 * Services are not always active so subscribing to {@link AuthService#authChange$} is not enough to determine when to
 * delete a cookie. Meanwhile this service will always be active, thus can properly manage cookies on auth change.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthCookieService {
  private readonly cookies: ICookie[] = [];

  constructor(private businessLocationCookie: BusinessLocationCookie) {
    this.cookies = [
      businessLocationCookie
    ];
  }

  public deleteAll(): void {
    this.cookies.forEach(cookie => cookie.delete());
  }
}
