import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { TitleService } from './core/services';
import { environment } from 'environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private gaId = environment.huterra.analytics.google.accountId;
  private destroy$ = new Subject<void>();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private titleService: TitleService) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let route = this.route;
          while (route.firstChild) route = route.firstChild;
          return route.snapshot.data;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        if (data && data.title) {
          this.titleService.setTitle(data.title, data.titleFormat);
        } else {
          this.titleService.setTitleToDefault();
        }
      });

    this.dynamicallyLoadGA();

    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', this.gaId,
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  dynamicallyLoadGA() {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.gaId;
    document.head.appendChild(script);
  }
}
