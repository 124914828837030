export type RequestContentType = 'application/json' |
                                    'application/json;charset=utf-8' |
                                    'application/x-www-form-urlencoded' |
                                    'application/x-www-form-urlencoded;charset=utf-8' |
                                    'text/csv';
export class RequestType {
    static Json: RequestContentType = 'application/json;charset=utf-8';
    static FormUrlEncoded: RequestContentType =  'application/x-www-form-urlencoded;charset=utf-8';
    static TextCsv: RequestContentType = 'text/csv';
}

