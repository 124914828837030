<form (ngSubmit)="createMyTeam()" #createTeamForm="ngForm">
  <div class="row">
    <div class="form-group col-12">
      <label class="required">Council Name</label>
      <input type="text" id="council-name" name="councilName" class="form-control" [(ngModel)]="model.councilName"
             autofocus required>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-12">
      <label class="required">Troop Name</label>
      <input type="text" id="troop-name" name="troopName" class="form-control"
             [(ngModel)]="model.troopName" required>
    </div>
  </div>

  <div class="row team-name-row">
    <div class="form-group col-12">
      <label>Team Name</label>
      <input type="text" id="team-name" name="teamName" class="form-control"
             [ngModel]="teamName" required disabled aria-describedby="team-name-hint">
      <small id="team-name-hint" class="form-text text-muted">
        Your team name is automatically generated by the information you've provided us.
      </small>
    </div>
  </div>

  <div class="row">
    <alert [type]="alert.type" *ngIf="alert?.msg" dismissible="true" class="col-12">
      {{ alert.msg }}
    </alert>
  </div>

  <div class="row">
    <div class="col-12 text-center">
      <button type="submit" class="btn btn-primary btn-padded" [disabled]="!createTeamForm.form.valid || isSubmitting">
        <i class="fas fa-spinner fa-spin" *ngIf="isSubmitting"></i>
        <span *ngIf="!isSubmitting">Create a Team</span>
      </button>
    </div>
  </div>
</form>
