import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { HttpService, IEarnResponse } from '../../http';
import { Fundraiser } from '../../../shared/models';
import { withoutNullProperties } from '../../../shared/util';

@Injectable({
  providedIn: 'root'
})
export class LegacyFundraiserService {
  constructor(private http: HttpService) { }

  public getFundraiser(id: string) {
    return this.http.get<IEarnResponse & { fundraiser: Fundraiser }>(`/v3/fundraiser/${id}`)
      .pipe(map(res => res.fundraiser), first());
  }

  /**
   * Search for fundraisers by name.
   * @param search - Partial fundraiser to search for.
   * @param limit - Maximum number of results to return.
   */
  public searchFundraisers(search: string, limit: number | string = 10, teamsOnly = false): Observable<Fundraiser[]> {
    const options: any = {
      params: {
        search: search,
        limit: limit,
        teamsOnly: teamsOnly
      }
    };

    return this.http.get<IEarnResponse & { fundraisers: Fundraiser[] }>('/v3/fundraiser', options)
      .pipe(map(res => {
        return res.fundraisers;
      }));
  }
}
