import { envBase } from './environment.base';

export const environment = Object.assign({}, envBase, {
  env: 'test',
  /**
   * The API the frontend speaks to.
   *
   * @remarks
   * NOT Earn2, but the URL to this project's sails API routes.
   */
  apiUrl: 'https://test2.myraisify.com/api/',
  earnApiUrl: 'https://testearnapi.myraisify.com/v3/',
  huterra: {
    website: {
      url: 'https://test.myraisify.com/',
      legacy: {
        url: 'https://testlegacy.myraisify.com/',
      }
    },
    analytics: {
      google: {
        accountId: 'UA-32668042-3'
      }
    },
  },
  raiseUp: {
    website: {
      url: 'http://stage.raiseup.org/'
    }
  },
  plaid: {
    publicKey: '4ca4d776242b414072c2f3df5f7dab',
    environment: 'sandbox',
    linkScriptUrl: 'https://cdn.plaid.com/link/v2/stable/link-initialize.js'
  },
  google: {
    apiKey: 'AIzaSyA4nqan3dcSdz1gd1WJlx_tJX1Fu95PrOI',
    recaptchaSiteKey: '6LclT5clAAAAAGabA_V2M4sdMFkdXl1koSw7z3hy',
  },
  log: {
    level: 'info'
  }
});
