<div class="card-title text-center">
    <h3>Sign Up</h3>
</div>

<div class="row">
    <mat-horizontal-stepper labelPosition="bottom" linear id="stepper" *ngIf="!skipWizard">
        <mat-step label="Create account" [completed]="false" editable="false">
            <ng-container *ngTemplateOutlet="formOnly"></ng-container>
        </mat-step>
        <mat-step [label]="stepDescription" [completed]="false" editable="false" *ngIf="!fundraiserStepDisabled">
            <app-intermediate-step (success)="next()"
                                   [options]="{ searchQuery: promo, fundraiserId: fundraiserId, teamId: teamId }">
            </app-intermediate-step>
        </mat-step>
        <mat-step label="Start shopping" [completed]="false" editable="false">
            <app-start-shopping mode="signup"></app-start-shopping>
        </mat-step>
    </mat-horizontal-stepper>

  <div class="form-only-container" *ngIf="skipWizard">
    <ng-container *ngTemplateOutlet="formOnly"></ng-container>
  </div>
</div>

<ng-template #formOnly>
  <app-sign-up-form
    [inviteId]="inviteId" [promo]="promo" [fundraiserId]="fundraiserId" [teamId]="teamId"
    (success)="next()">
  </app-sign-up-form>

  <div class="text-center color-light size-14">
    Already have an account?&nbsp;<a class="app-link" routerLink="/login" queryParamsHandling="preserve">Sign in</a>
  </div>
</ng-template>
