import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService, IEarnResponse } from '../../http';
import { map } from 'rxjs/operators';
import { GeocodeData } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  public permissionState$ = new BehaviorSubject<PermissionState>(null);

  private readonly defaultPositionOptions: PositionOptions = {
    enableHighAccuracy: true,
    maximumAge: 60 * 1000,
    timeout: 15 * 1000
  };

  constructor(private http: HttpService) {
    this.watchPermissionState();
  }

  /**
   * Get the user's current position using the browser's geolocation API.
   * Will error if user has denied permission or the browser does not support geolocation.
   */
  public getCurrentPosition(options?: PositionOptions): Observable<GeolocationPosition> {
    return new Observable<GeolocationPosition>(observer => {
      if (!navigator.geolocation) {
        observer.error('Browser does not support geolocation.');
        return;
      }

      navigator.geolocation.getCurrentPosition(
        position => {
          observer.next(position);
          observer.complete();
        },
        err => observer.error(err),
        options || this.defaultPositionOptions
      );
    });
  }

  private async watchPermissionState(): Promise<void> {
    if (navigator.permissions) {
      const status: PermissionStatus = await navigator.permissions.query({ name: 'geolocation' });
      status.onchange = (event: Event) => {
        const target = event.target as PermissionStatus;
        this.permissionState$.next((target && target.state) || 'denied');
      };

      this.permissionState$.next(status.state);
    }

    this.permissionState$.next('denied');
  }
}
