import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Team } from '../../../shared/models';

@Component({
  selector: 'app-already-owned-teams-dialog',
  templateUrl: './already-owned-teams-dialog.component.html',
  styleUrls: ['./already-owned-teams-dialog.component.scss']
})
export class AlreadyOwnedTeamsDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { ownedTeams: Team[] },
              private dialogRef: MatDialogRef<AlreadyOwnedTeamsDialogComponent>) {}

  ngOnInit() {}

  public closeDialog(confirm: boolean, selectedTeam?: Team) {
    this.dialogRef.close({ confirm, selectedTeam });
  }
}
