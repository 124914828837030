export enum SignupActivityType {
  SIGNUP = 29,
  SHARE_SIGNUP,
  PROMO_SIGNUP
}

export enum CheckoutActivityType {
  PLACE_ORDER = 32,
  CANCEL_ORDER,
  SESSION_EXPIRES,
  PAYMENT_METHOD_CHANGE
}

export class ActivityType {
  static readonly Signup = SignupActivityType;
  static readonly Checkout = CheckoutActivityType;
}
