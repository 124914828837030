import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core';
import { UserRole } from './shared/models';
import { LogoutComponent } from './modules/logout/logout.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'account'
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    canLoad: [AuthGuard],
    data: { role: UserRole.User }
  },
  {
    path: 'business',
    loadChildren: () => import('./modules/business/business.module').then(m => m.BusinessModule)
  },
  {
    path: 'association',
    loadChildren: () => import('./modules/association/association.module').then(m => m.AssociationModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./modules/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    path: 'fundraiser',
    loadChildren: () => import('./modules/fundraiser/fundraiser.module').then(m => m.FundraiserModule)
  },
  {
    path: 'transfer',
    loadChildren: () => import('./modules/transfer/transfer.module').then(m => m.TransferModule)
  },
  {
    path: 'membership',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/membership/membership.module').then(m => m.MembershipModule)
  },
  {
    path: 'invite',
    loadChildren: () => import('./modules/invite/invite.module').then(m => m.InviteModule)

  },
  {
    path: 'logout',
    component: LogoutComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
