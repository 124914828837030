import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BrowserDetectService, BrowserName } from './browser-detect.service';

export interface BrowserExtension {
  browser: string;
  url: string;
  logo: string;
  /** FontAwesome icon name. */
  icon: string;
  target?: '_blank' | '_self';
}

@Injectable({
  providedIn: 'root'
})
export class BrowserExtensionService {
  public readonly extensions: { [browserName: string]: BrowserExtension } = {
    chrome: {
      browser: BrowserName.CHROME,
      url: 'https://chrome.google.com/webstore/detail/raiseup/dfikipdgmkikllbaghkcphhikakfgimk',
      logo: `${environment.cdnUrl}vendor/logo-chrome.svg`,
      icon: 'chrome',
      target: '_blank'
    },
    firefox: {
      browser: BrowserName.FIREFOX,
      url: 'https://addons.mozilla.org/firefox/downloads/latest/raise-up/addon-835519-latest.xpi',
      logo: `${environment.cdnUrl}vendor/logo-firefox.svg`,
      icon: 'firefox'
    },
    edge: {
      browser: BrowserName.EDGE,
      url: 'https://chrome.google.com/webstore/detail/raiseup/dfikipdgmkikllbaghkcphhikakfgimk',
      logo: `${environment.cdnUrl}vendor/logo-edge.svg`,
      icon: 'edge',
      target: '_blank'
    }
  };

  /**
   * The browsers to be promoted to the user to download.
   * If primary is null, all browsers will be promoted equally.
   */
  public targets: { primary: BrowserExtension, secondary: BrowserExtension } = {
    primary: null,
    secondary: null
  };

  constructor(private browserDetect: BrowserDetectService) {
    this.setBrowserTargets();
  }

  private setBrowserTargets(): void {
    switch (this.browserDetect.browserName) {
      case BrowserName.CHROME:
        this.targets = {
          primary: this.extensions.chrome,
          secondary: this.extensions.firefox
        };
        break;

      case BrowserName.FIREFOX:
        this.targets = {
          primary: this.extensions.firefox,
          secondary: this.extensions.chrome
        };
        break;

      case BrowserName.EDGE:
        this.targets = {
          primary: this.extensions.edge,
          secondary: this.extensions.chrome
        };
        break;

      default:
        this.targets = {
          primary: null,
          secondary: null
        };
    }
  }
}
