import { Component, Input } from "@angular/core";

@Component({
    selector: "fundraiser-description",
    templateUrl: "./fundraiserDescription.component.html",
    styleUrls: ['./fundraiserDescription.component.scss']
})
export class FundraiserDescriptionComponent {
    @Input() topText: string
    @Input() bottomText: string
    @Input() middleText: string
    @Input() imageUrl: string
}