import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { RouterHistoryService } from './router-history.service';

@Injectable()
export class NavigationService {
  private _showNav: boolean = true;

  constructor(private router: Router,
              private location: Location,
              private routerHistory: RouterHistoryService) {
  }

  public get showNav() {
    return this._showNav;
  }

  public get canGoBack(): boolean {
    return this.routerHistory.hasHistory;
  }

  setShowNav(val: any) {
    this._showNav = val === true || val === 'true';
  }

  goBack() {
    if (!this.canGoBack) window.close();
    else this.location.back();
  }

  getMatIcon() {
    return 'keyboard_arrow_left';
  }
}
