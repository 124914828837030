/**
* Check if a value is a string.
* @returns Whether or not the value is a string.
*/
export function isString(value: any): boolean {
  return (typeof value === 'string' || value instanceof String);
}

/**
 * Trim the ending of a string if it matches any of the given string.
 * @param value - String to trim.
 * @param trimStr - Possible ending to {@link value} that should be removed.
 */
export function trimEnd(value: string, trimStr: string): string {
  return value.endsWith(trimStr)
    ? value.substring(0, value.length - trimStr.length)
    : value;
}

/**
 * Trim the start of a string if it matches any of the given string.
 * @param value - String to trim.
 * @param trimStr - Possible start to {@link value} that should be removed.
 */
export function trimStart(value: string, trimStr: string): string {
  return value.startsWith(trimStr)
    ? value.substring(trimStr.length)
    : value;
}

/**
* Convert a string to title case.
* @param value - String to convert.
* @returns String, in title case.
*/
export function toTitleCase(value: string): string {
  return value
    ? value.toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : '';
}

/**
 * Check whether a string is a UUID (any version).
 * @param value - String to check.
 * @param allowDashlessUUID - Whether to allow dashless UUIDs.
 */
export function isUUID(value: string, allowDashlessUUID: boolean = false): boolean {
  const uuidRe = /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i;
  const uuidDashlessRe = /^[0-9A-F]{32}$/i;

  return uuidRe.test(value) || (allowDashlessUUID && uuidDashlessRe.test(value));
}

/**
 * Remove the dashes from a string.
 * In the case of UUIDs, couch requires them to be dash-less.
 */
export function removeDashes(value: string): string {
  return value.replace(/-/g, '');
}
