import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Logger } from '../../../logger.service';
import { environment } from '../../../../../environments/environment';
import { CookieOptions } from './cookie.model';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  /**
   * Per [RFC 6265](https://www.ietf.org/rfc/rfc6265.txt), browsers must allow at least 4096 bytes per cookie.
   */
  private readonly MAX_COOKIE_LENGTH = 4096;

  constructor(@Inject(DOCUMENT) private document: Document,
              private logger: Logger) { }

  public set(name: string, value: string, options: CookieOptions = {}): void {
    let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)};path=${options.path || '/'};`;
    if (options.domain) cookie += `domain=${options.domain};`;
    if (options.expires) cookie += `expires=${options.expires.toUTCString()};`;
    if ((options.secure == null && environment.env !== 'dev') || options.secure) cookie += `secure;`;
    if (options.httpOnly) cookie += `httpOnly;`;

    if (cookie.length > this.MAX_COOKIE_LENGTH) {
      this.logger.warn(`Cookie "${name}" may not have been set. Max cookie size required to be supported by `
        + `browsers exceeded. Max size: ${this.MAX_COOKIE_LENGTH}. Cookie size: ${cookie.length}. See RFC 6265.`);
    }

    this.document.cookie = cookie;
  }

  public get(name: string): string {
    const cookies = this.document.cookie.split(';');
    for (const cookie of cookies) {
      const [aName, aValue] = cookie.trim().split('=');
      if (aName === encodeURIComponent(name)) return decodeURIComponent(aValue);
    }

    return null;
  }

  public delete(name: string, options: CookieOptions = {}): void {
    // Setting the cookie expiration to the past will automatically delete the cookie.
    options = Object.assign({}, options, { expires: new Date(0) });
    this.set(name, '', options);
  }
}
