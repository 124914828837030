<form (ngSubmit)="createMyTeam()" #createTeamForm="ngForm">
  <div class="row">
    <div class="form-group col-12">
      <label>Team Name</label>
      <input type="text" id="team-name" name="teamName" class="form-control" [(ngModel)]="teamName"
             required autofocus aria-describedby="team-name-hint">
      <small id="team-name-hint" class="form-text text-muted">
        Make it easy for others to find your team fundraiser by including your family name and mom’s and dad’s initials or the last name and initial of your child (E.g. “Smith, A & C” or “Jones, M”). It is best not to use the full name of your children.
      </small>
    </div>
  </div>

  <div class="row">
    <alert [type]="alert.type" *ngIf="alert?.msg" dismissible="true" class="col-12">
      {{ alert.msg }}
    </alert>
  </div>

  <div class="row">
    <div class="col-12 text-center">
      <button type="submit" class="btn btn-primary btn-padded" [disabled]="!createTeamForm.form.valid || isSubmitting">
        <i class="fas fa-spinner fa-spin" *ngIf="isSubmitting"></i>
        <span *ngIf="!isSubmitting">Create a Team</span>
      </button>
    </div>
  </div>
</form>
