import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export const UNSAVED_CHANGES_MSG = 'You have unsaved changes. Are you sure you want to leave this page?';

export interface ComponentCanDeactivate {
  /**
   * The associated {@link UnsavedChangesGuard} only guards again navigating away WITHIN the app.
   * To also guard again navigating AWAY from the app (to another website, refresh, closing, etc.), use the
   * decorator `@HostListener('window:beforeunload')`.
   */
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    return component.canDeactivate() ? true : confirm(UNSAVED_CHANGES_MSG);
  }
}
