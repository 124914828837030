import { GiveType } from './give-type.enum';
import { GivePercentDto } from './give-percent-dto.model';
import { BusinessActionIcon } from './business-action-icon.model';

export interface IGivePercent extends GivePercentDto {
  readonly type: GiveType |  null;
  readonly label: string;
  readonly icon: BusinessActionIcon;
  readonly inAppOnly: boolean;
}

export class GiftCardGivePercent implements IGivePercent {
  public readonly label: string = 'Buy Gift Card';
  public readonly icon: BusinessActionIcon = new BusinessActionIcon('material', 'payment');
  public readonly inAppOnly = true;

  constructor(public percent: number,
              public isUpTo: boolean,
              public giftCardBusinessId: string,
              public type: GiveType.EGiftCard | GiveType.PhysicalGiftCard = GiveType.EGiftCard) {
  }
}

export class OnlineGivePercent implements IGivePercent {
  public readonly type = GiveType.ShopOnline;
  public readonly label: string = 'Shop Online';
  public readonly icon: BusinessActionIcon = new BusinessActionIcon('material', 'shopping_basket');
  public readonly inAppOnly = false;

  constructor(public percent: number,
              public isUpTo: boolean,
              public onlineStoreUrl: string) {
  }
}

export class LocalGivePercent implements IGivePercent {
  public readonly type = GiveType.ShopLocal;
  public readonly label: string = 'Snap to Earn';
  public readonly icon: BusinessActionIcon = new BusinessActionIcon('fa', 'receipt');
  public readonly inAppOnly = true;

  constructor(public percent: number, public isUpTo: boolean) {}
}

/**
 * A hacky way to fit deals into {@link GivePercentItemComponent}.
 */
export class DealsGivePercent implements IGivePercent {
  public readonly type = null;
  public readonly label: string = '';
  public readonly icon: BusinessActionIcon = new BusinessActionIcon('material', 'sell');
  public readonly inAppOnly = false;
  public readonly percent = null;
  public readonly isUpTo = false;

  constructor(dealsNumber: number) {
    this.label = `${dealsNumber} Deal${dealsNumber > 1 ? 's' : ''} Available`;
  }
}
