import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestType } from '../models/request-type.model';

/**
 * Apply default headers to HTTP requests.
 *
 * @remarks
 * Default headers are:
 * - Content-Type: application/json
 */
@Injectable()
export class DefaultHeadersInterceptor implements HttpInterceptor {
  constructor() {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers: any = {};

    if (!req.headers.has('Content-Type') && !(req.body instanceof FormData)) {
      headers['Content-Type'] = RequestType.Json;
    }

    req = req.clone({ setHeaders: headers });
    return next.handle(req);
  }
}
