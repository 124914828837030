import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { IRequestOptions } from './models/request-options.model';
import { ResponseType } from './models/response-type.model';
import { RequestType } from './models/request-type.model';
import { isNullOrEmpty, isString, trimEnd, trimStart } from '../../shared/util';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(protected http: HttpClient) { }

  /**
   * Perform a DELETE request that interprets the body as JSON.
   * @param endpoint - Endpoint, relative to base URL (e.g., '/api/customer').
   * @param options - Options for the DELETE request.
   * @param baseUrlOverride - Override for default base URL.
   * @returns Observable of the DELETE request that resolves to the given generic type.
   */
  public delete<TSuccess>(
    endpoint: string, options?: IRequestOptions, baseUrlOverride?: string): Observable<TSuccess> {
      return this.request('DELETE', endpoint, options, baseUrlOverride);
  }

  /**
   * Perform a GET request that interprets the body as JSON.
   * @param endpoint - Endpoint, relative to base URL (e.g., '/api/customer').
   * @param options - Options for the GET request.
   * @param baseUrlOverride - Override for default base URL.
   * @returns Observable of the GET request that resolves to the given generic type.
   */
  public get<TSuccess>(endpoint: string, options?: IRequestOptions, baseUrlOverride?: string): Observable<TSuccess> {
    return this.request('GET', endpoint, options, baseUrlOverride);
  }

  /**
   * Perform a GET request that returns a blob. Accept type defaults to 'xlsx' files.
   * @param endpoint - Endpoint, relative to base URL (e.g., '/api/customer').
   * @param options - Options for the GET request.
   * @param baseUrlOverride - Override for default base URL.
   * @returns Observable of the GET request that resolves to a blob.
   */
  public getBlob(endpoint: string, options: IRequestOptions = {}, baseUrlOverride?: string): Observable<Blob> {
    if (!this.hasHeader(options.headers, 'Content-Type')) {
      options.headers = this.setHeader(options.headers, 'Content-Type', RequestType.Json);
    }

    if (!this.hasHeader(options.headers, 'Accept')) {
      options.headers = this.setHeader(options.headers,
        'Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }

    options.responseType = ResponseType.Blob;
    return this.request('GET', endpoint, options, baseUrlOverride);
  }

  /**
   * Perform a PATCH request that interprets the body as JSON.
   * @param endpoint - Endpoint, relative to base URL (e.g., '/api/customer').
   * @param options - Options for the PATCH request.
   * @param baseUrlOverride - Override for default base URL.
   * @returns Observable of the PATCH request that resolves to the given generic type.
   */
  public patch<TSuccess>(
    endpoint: string, options?: IRequestOptions, baseUrlOverride?: string): Observable<TSuccess> {
      return this.request('PATCH', endpoint, options, baseUrlOverride);
  }

  /**
   * Perform a POST request that interprets the body as JSON.
   * @param endpoint - Endpoint, relative to base URL (e.g., '/api/customer').
   * @param options - Options for the POST request.
   * @param baseUrlOverride - Override for default base URL.
   * @returns Observable of the POST request that resolves to the given generic type.
   */
  public post<TSuccess>(
    endpoint: string, options?: IRequestOptions, baseUrlOverride?: string, returnErrorCode = false): Observable<TSuccess> {
      return this.request('POST', endpoint, options, baseUrlOverride, returnErrorCode);
  }

  /**
   * Perform a POST request for file in the form of form data.
   * @param endpoint - Endpoint, relative to base URL (e.g., '/api/customer').
   * @param fileKey - Name of parameter to send file as.
   * @param file - File to send.
   * @param options - Options for the POST request.
   * @param baseUrlOverride - Override for default base URL.
   * @returns Observable of the POST request that resolves to the given generic type.
   */
  public postFile<TSuccess>(
    endpoint: string, fileKey: string, file: File, options?: any, baseUrlOverride?: string
  ): Observable<TSuccess | ArrayBuffer> {
      const formData = new FormData();
      formData.append(fileKey, file, file.name);

      return this.http.post(this.getFullEndpoint(endpoint, baseUrlOverride), formData, options)
        .pipe(catchError((err) => this.handleError(err, false)));
  }

  /**
   * Perform a PUT request that interprets the body as JSON.
   * @param endpoint - Endpoint, relative to base URL (e.g., '/api/customer').
   * @param options - Options for the PUT request.
   * @param baseUrlOverride - Override for default base URL.
   * @returns Observable of the PUT request that resolves to the given generic type.
   */
  public put<TSuccess>(
    endpoint: string, options?: IRequestOptions, baseUrlOverride?: string): Observable<TSuccess> {
      return this.request('PUT', endpoint, options, baseUrlOverride);
  }

  /**
   * Perform an HTTP request.
   * @param method
   * @param endpoint - Endpoint, relative to base URL (e.g., '/api/customer').
   * @param options - Options for the request.
   * @param baseUrlOverride - Override for default base URL.
   * @returns Observable of the HTTP request that resolves to the given generic type.
   */
  public request<TSuccess>(
    method: string, endpoint: string, options?: IRequestOptions, baseUrlOverride?: string, returnErrorCode: boolean = false): Observable<TSuccess> {
      if (options && !(options.body instanceof FormData)) {
        options.body = this.serializeBody(options.body, options);
      }

      return this.http.request(method, this.getFullEndpoint(endpoint, baseUrlOverride), options)
        .pipe(catchError((err) => this.handleError(err, returnErrorCode)), first());
  }

  /**
   * Handle any errors that occur while performing requests.
   */
  private handleError(error: HttpErrorResponse, returnErrorCode: boolean): Observable<never> {
    let genericError = 'Unknown error';
    let errorMsg: string;

    if (error.hasOwnProperty('status')) {
      // The server returned an unsuccessful response code.
      genericError = 'Server error';
      if (isString(error.error)) {
        try {
          const errObj = JSON.parse(error.error);
          errorMsg = isString(errObj) ? errObj : errObj.message;
        } catch {
          errorMsg = error.error;
        }
      } else if (error.error && error.error.message) {
        errorMsg = error.error.message;
      }
    } else if (error.error instanceof ErrorEvent) {
      // A client-side or network error.
      genericError = 'Network error';
      errorMsg = error.error.message;
    } else {
      genericError = 'Client-side error';
      errorMsg = error.message;
    }

    if (environment.env !== 'prod') {
      console.log(error);
    }

    if (returnErrorCode) {
      errorMsg = {code: error.error.code, message: errorMsg} as any
    }

    return throwError(isNullOrEmpty(errorMsg) ? genericError : errorMsg);
  }

  /**
   * Convert a relative endpoint to a full endpoint.
   * @param endpoint - Endpoint, relative to a base URL (e.g., '/api/customer').
   * @param baseUrlOverride - Override for default base URL.
   * @returns The full URL for an endpoint.
   */
  private getFullEndpoint(endpoint: string, baseUrlOverride?: string): string {
    if (endpoint.match(/^https?:\/\//)) {
      return endpoint;
    }

    let baseUrl = baseUrlOverride != null ? baseUrlOverride : environment.apiUrl;
    baseUrl = trimEnd(baseUrl, '/') + (baseUrl === '' ? '' : '/');
    return baseUrl + trimStart(endpoint, '/');
  }

  /**
    * Check whether headers contain a specific header.
    * @param headers - Header to check against.
    * @param header - Header to look for.
    */
  private hasHeader(headers: HttpHeaders | { [header: string]: string | string[]; }, header: string): boolean {
    if (!headers) return false;

    if (headers instanceof HttpHeaders) {
      return headers.has(header);
    }

    return Object.keys(headers)
                .map(key => key.toLowerCase())
                .includes(header.toLowerCase());
  }

  private getHeader(
    headers: HttpHeaders | { [header: string]: string | string[]; },
    header: string): string | string[] {
      if (!headers) return null;

      if (headers instanceof HttpHeaders) {
        return headers.get(header);
      }

      return headers[header];
  }

  /**
    * Set a header to a value and returns the changed headers.
    * @param headers - Headers to change.
    * @param header - Header key to set.
    * @param value - Value to set header.
    * @returns The headers with the new header set.
    */
  private setHeader(headers: HttpHeaders | { [header: string]: string | string[]; },
    header: string, value: string | string[]): HttpHeaders | { [header: string]: string | string[]; } {
      if (headers instanceof HttpHeaders) {
        headers = headers.set(header, value);
      } else {
        if (!headers) headers = {};
        headers[header] = value;
      }

      return headers;
  }

  /**
   * Convert an object to JSON or form url encoded to pass into a request's body.
   * @param body - Body of request.
   * @param options - Options for the request.
   * @returns Stringified object, or null if not applicable.
   */
  private serializeBody(body: any | null, options?: IRequestOptions): string | null {
    if (body == null) return null;

    // Default to JSON if no header is set.
    const isJson = (!options || !options.headers
                    || this.getHeader(options.headers, 'Content-Type') === RequestType.Json);
    if (isJson) return JSON.stringify(body);

    if (this.getHeader(options.headers, 'Content-Type') === RequestType.FormUrlEncoded) {
      const values = [];
      for (const key in body) {
        if (body.hasOwnProperty(key)) {
          values.push(key + '=' + (body as any)[key]);
        }
      }
      return values.join('&');
    }

    return null;
  }
}
