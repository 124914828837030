import { NgModule } from '@angular/core';
import { StartEarningComponent } from './start-earning/start-earning.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AlertModule } from 'ngx-bootstrap/alert';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { AddFundraisersStepComponent } from './intermediates/add-fundraisers-step/add-fundraisers-step.component';
import { SharedModule } from 'app/shared';
import { StartShoppingComponent } from './sign-up/start-shopping/start-shopping.component';
import { CreateMyTeamModule } from '../create-my-team/create-my-team.module';
import { IntermediateStepComponent } from './intermediates/intermediate-step.component';
import { CreateTeamStepComponent } from './intermediates/create-team-step/create-team-step.component';
import { CreateClassroomTeamStepComponent } from './intermediates/create-classroom-team-step/create-classroom-team-step.component';
import { CreateMinistryTeamStepComponent } from './intermediates/create-ministry-team-step/create-ministry-team-step.component';
import { IntermediateStepResolver } from './intermediates/intermediate-step-resolver.service';
import { CreateTroopTeamStepComponent } from './intermediates/create-troop-team-step/create-troop-team-step.component';
import { VerifyComponent } from './verify/verify.component';
import { AccountAuthRoutingModule } from './account-auth-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { AuthFormModule } from '../auth/auth-form/auth-form.module';
import { GettingStartedModule } from '../getting-started/getting-started.module';

const components: any[] = [
    StartEarningComponent,
    SignInComponent,
    SignUpComponent,
    AddFundraisersStepComponent,
    StartShoppingComponent,
    IntermediateStepComponent,
    CreateTeamStepComponent,
    CreateClassroomTeamStepComponent,
    CreateMinistryTeamStepComponent,
    CreateTroopTeamStepComponent,
    VerifyComponent
];
const providers: any[] = [
    IntermediateStepResolver
];

@NgModule({
    declarations: components,
    providers: providers,
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        AlertModule.forRoot(),
        AccountAuthRoutingModule,
        AuthFormModule,
        MatStepperModule,
        MatIconModule,
        SharedModule,
        CreateMyTeamModule,
        GettingStartedModule
    ],
    exports: [AccountAuthRoutingModule, ...components]
})
export class AccountAuthModule {}
