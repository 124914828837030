import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService, IEarnResponse, IPagination, IRequestOptions } from '../../http';
import { Community } from '../../../shared/models';
import { withoutNullProperties } from '../../../shared/util';
import { map } from 'rxjs/operators';
import { CacheService } from '../utility/cache.service';

export type CommunitySearchType = 'fein' | 'nameId';

interface GetCommunityOptions {
  // Additional attributes to include. Admin only.
  include: ('address' | 'qbVendorId')[];
  noCache: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CommunityService {
  private readonly cacheKeys = {
    community: 'getCommunity',
    communities: 'getCommunities'
  };

  constructor(private http: HttpService,
              private cache: CacheService) { }

  public getCommunity(id: string, options: Partial<GetCommunityOptions> = { noCache: false }): Observable<Community> {
    const reqOptions: IRequestOptions = {
      params: withoutNullProperties({
        include: options.include && options.include.join(',')
      })
    };

    const req$ = this.http.get<IEarnResponse & { community: Community}>(`/community/${id}`, reqOptions)
      .pipe(map(res => res.community));

    return this.cache.setIfNotExists(this.cacheKeys.community, req$, {
      ttl: 10 * 60 * 1000,
      args: Object.assign({ id }, options),
      noCache: options.noCache
    });
  }

  /**
   * Get communities based on a given set of criteria.
   * @param searchQuery - Search query to filter communities by.
   * @param limit - Limit of the number of communities to retrieve.
   * @param offset - Offset communities retrieved.
   * @param searchType - Type of search being performed.
   */
  public getCommunities(searchQuery: string, limit: number = 5, offset: number = 0, searchType: CommunitySearchType = null):
    Observable<IPagination<Community>> {
    const options = {
      params: withoutNullProperties({
        limit: limit,
        offset: offset,
        search: searchQuery,
        searchType: searchType
      })
    };

    const req$ = this.http.get<IPagination<Community>>(`/admin/community`, options);
    return this.cache.setIfNotExists(this.cacheKeys.communities, req$, { ttl: 10 * 60 * 1000, args: options.params });
  }

  public searchCommunities(search: string, limit: number = 5): Observable<IPagination<Community>> {
    const options = {
      params: withoutNullProperties({
        search: search,
        limit: limit
      })
    };

    return this.http.get(`/v3/community`, options);
  }
}
