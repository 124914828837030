import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, NgZone } from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  host: {
    class: 'app-read-more',
    '[class.app-read-more--expanded]': `expanded === true`,
  }
})
export class ReadMoreComponent implements AfterViewInit {
  public expanded = false;
  public hasOverflow = false;

  constructor(private elementRef: ElementRef,
              private cdr: ChangeDetectorRef,
              private ngZone: NgZone) { }

  @HostListener('window:resize')
  private onResize(): void {
    this.ngZone.run(() => {
      this.detectOverflow();
    });
  }

  ngAfterViewInit() {
    this.detectOverflow();
  }

  toggleExpanded(): void {
    this.expanded = !this.expanded;
  }

  private detectOverflow(): void {
    const el: HTMLElement = this.elementRef.nativeElement;

    this.hasOverflow = el.offsetHeight < el.scrollHeight;
    this.cdr.detectChanges();
  }
}
