import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService, IEarnResponse } from '../../http';
import { Team } from '../../../shared/models/team.model';

@Injectable({
  providedIn: 'root'
})
export class TeamMemberService {
  constructor(private http: HttpService) { }

  public getMemberTeams(memberId: string): Observable<Team[]> {
    return this.http.get<IEarnResponse & { teams: Team[] }>(`/member/${memberId}/team`)
      .pipe(map(res => {
        return res.teams;
      }));
  }
}
