import { Component, Input, OnInit } from '@angular/core';
import { AccountMenuService } from '../../../core';

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
  host: {
    class: 'account-menu',
    '[class.account-menu-appearance-standard]': `['standard', 'hover'].includes(appearance)`,
    '[class.account-menu-appearance-hover]': `appearance === 'hover'`,
    '[class.account-menu-appearance-submenu]': `appearance === 'submenu'`,
    '[class.account-menu-authenticated]': `accountMenu && accountMenu.isAuthenticated`,
  }
})
export class AccountMenuComponent implements OnInit {
  /**
   * Whether the menu is a submenu of another menu.
   */
  @Input() appearance: 'standard' | 'hover' | 'submenu' = 'standard';

  constructor(public accountMenu: AccountMenuService) { }

  ngOnInit(): void {}
}
