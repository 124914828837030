import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { environment } from 'environments/environment';
import { ImagesService } from '../../../core';
import { LayoutTheme } from '../models/layout-theme.enum';
import { LayoutMode } from '../models/layout-mode.enum';
import { LayoutService } from '../layout.service';

interface HeaderRoute {
  title: string;
  classes?: string;

  /**
   * Non-Angular route to navigate to.
   */
  href?: string;
  target?: '_blank' | '_self';

  /**
   * Angular route to navigate to.
   */
  path?: string;

  children?: HeaderRoute[];
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  @Input() appearance: 'default' | 'admin' = 'default';

  @Input() noNav: boolean = false

  /**
   * Overrides whatever theme is setup on the route.
   */
  private _theme: LayoutTheme;
  @Input() set theme(theme: LayoutTheme) {
    this._theme = theme;
  }

  get theme() {
    return this._theme || this.layout.theme;
  }

  @Output() sidebarToggleClick: EventEmitter<void> = new EventEmitter();

  public LayoutMode = LayoutMode;

  public readonly brandName = environment.brandName;
  public readonly disableFundraisers = environment.brandName === '4GOODRewards';
  public websiteUrl = environment.huterra.website.url;
  public routes: HeaderRoute[] = [
    {
      title: 'Explore Businesses',
      path: '/business',
      children: [
        { title: 'Gift Cards', path: '/business/gift-card' },
        { title: 'Online', path: '/business/online' },
        { title: 'Deals', path: '/business/deals' },
      ]
    },
    {
      title: 'Shop Online',
      path: '/business/online'
    }
  ];

  get logo() {
    return this.theme === LayoutTheme.LIGHT ? this.images.logo : this.images.logoHorizontalWhite;
  }

  get brandMark() {
    return this.theme === LayoutTheme.LIGHT ? this.images.brandmark : this.images.brandmarkWhite;
  }

  constructor (public images: ImagesService,
               public layout: LayoutService) {
    if (!this.disableFundraisers) {
      this.routes = [
        {
          title: 'Create a Fundraiser',
          href: `${environment.huterra.website.url}info/fundraisers/wizard`
        },
        ...this.routes
      ];
    }
  }

  ngOnInit() {}

  public toggleSidebar(): void {
    this.sidebarToggleClick.emit();
  }
}
