<h5>Download the App</h5>

<div class="color-light">
  <p>
    Download the {{appName}} app and start earning while shopping at your favorite stores.
  </p>

  <div class="app-badges">
    <app-badge type="ios"></app-badge>
    <app-badge type="android"></app-badge>
  </div>
</div>

<p *ngIf="!disableMyFundraisers">
  Use the app to share your fundraiser to get others to join in and raise more for your cause.
</p>
