import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../auth';
import { Logger } from '../../logger.service';

/**
 * Update auth if a request fails due to an invalid or expired access token.
 */
@Injectable()
export class InvalidAccessTokenInterceptor implements HttpInterceptor {
  /**
   * Error codes from Earn2 that mean the access token is invalid or expired.
   */
  private readonly errorCodes = [ 1000, 1001 ];

  constructor(private auth: AuthService,
              private logger: Logger) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err && err.status === 403 && err.error && this.errorCodes.includes(err.error.code)) {
          this.logger.debug(`Received 403 (Forbidden) - ${err.error.code} (Invalid Access Token). Logging user out.`);
          this.auth.updateAuth({ isAuthenticated: false });
        }

        return throwError(err);
      }));
  }
}
