import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';

/**
 * Wrapper around Angular's own {@link Title} service that sets the title in the HTML document.
 */
@Injectable({
  providedIn: 'root'
})
export class TitleService {
  public static readonly separator = ' | ';
  public static readonly orgName = environment.brandName;

  constructor(private titleService: Title) {
    this.setTitleToDefault();
  }

  public getTitle(): string {
    return this.titleService.getTitle();
  }

  public setTitle(newTitle: string, format: 'prefix' | 'postfix' | 'none' = 'postfix'): void {
    let formattedTitle = newTitle;
    if (format === 'prefix') formattedTitle = TitleService.orgName + TitleService.separator + newTitle;
    else if (format === 'postfix') formattedTitle = newTitle + TitleService.separator + TitleService.orgName;

    this.titleService.setTitle(formattedTitle);
  }

  public setTitleToDefault(): void {
    this.setTitle(environment.tagline, 'prefix');
  }
}
