import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LayoutTheme } from './models/layout-theme.enum';
import { LayoutMode } from './models/layout-mode.enum';

export interface ILayoutData {
  theme?: LayoutTheme;
  mode?: LayoutMode;
  hideFooterDivider?: boolean;

  noNav?: boolean;

  /**
   * Whether the content should be padded at the top and bottom, to give space between the content, header, and footer.
   */
  noContentPadding?: boolean;
}

export class LayoutData implements ILayoutData {
  public theme = LayoutTheme.DARK;
  public mode = LayoutMode.DEFAULT;
  public hideFooterDivider = false;
  public noContentPadding = false;
  public noNav = false

  constructor(data?: ILayoutData) {
    Object.assign(this, data);
  }
}

@Injectable({
  providedIn: 'root'
})
export class LayoutService implements OnDestroy {
  public mode: LayoutMode = LayoutMode.DEFAULT;
  public theme: LayoutTheme = LayoutTheme.DARK;
  public hideFooterDivider = false;
  public noContentPadding = false;

  private _defaultOptions: LayoutData = new LayoutData();
  public get defaultOptions() { return this._defaultOptions; }
  public set defaultOptions(defaultOptions: LayoutData) {
    this._defaultOptions = defaultOptions;
    this.updateLayout();
  }

  private destroy$ = new Subject<void>();

  constructor(private router: Router,
              private route: ActivatedRoute) {
    this.updateLayout();

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd),
            takeUntil(this.destroy$))
      .subscribe(() => this.updateLayout());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateLayout(): void {
    const data = this.getLayoutData() || {} as ILayoutData;

    this.theme = data.theme || this.defaultOptions.theme;
    this.mode = (data.mode in LayoutMode) ? data.mode : this.defaultOptions.mode;
    this.hideFooterDivider = data.hideFooterDivider != null ? !!data.hideFooterDivider
                                                            : this.defaultOptions.hideFooterDivider;
    this.noContentPadding = data.noContentPadding != null ? !!data.noContentPadding
                                                          : this.defaultOptions.noContentPadding;
  }

  private getLayoutData(): ILayoutData {
    let route = this.route.snapshot;
    let data: ILayoutData = {} as ILayoutData;

    /**
     * Collect the layout properties going from parent to child down the tree.
     */
    while (route.firstChild) {
      route = route.firstChild;
      if (route.data && route.data.layout) {
        data = Object.assign(data, route.data.layout);
      }
    }

    return data;
  }
}
