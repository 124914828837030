import { Component } from '@angular/core';
import { LegacyTeamService } from '../../../../core/services';
import { Logger } from '../../../../core';
import { CreateTeamStepComponentBase } from '../create-team-step-base.component';

@Component({
  selector: 'app-create-team-step',
  templateUrl: './create-team-step.component.html',
  styleUrls: ['./create-team-step.component.scss']
})
export class CreateTeamStepComponent extends CreateTeamStepComponentBase {
  public teamName: string;

  constructor(protected teamService: LegacyTeamService,
              protected logger: Logger) {
    super(teamService, logger);
  }
}
