import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService, IEarnResponse, Logger } from '../../../core';

@Injectable({
  providedIn: 'root'
})
export class VerifyResolver implements Resolve<IEarnResponse> {
  constructor(private accountService: AccountService,
              private logger: Logger) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IEarnResponse> {
    return this.accountService.verifyEmail(route.queryParamMap.get('email'), route.queryParamMap.get('code'))
      .pipe(catchError(error => {
        this.logger.error(error);
        return of({
          success: false,
          message: error
        });
      }));
  }
}
