import {Injectable, OnDestroy, Type} from '@angular/core';
import {IIntermediateStepComponent} from './intermediate-step.model';
import {ActivatedRoute} from '@angular/router';
import { Subject } from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TeamNameFormatType} from '../../../shared/models';
import {CreateClassroomTeamStepComponent} from './create-classroom-team-step/create-classroom-team-step.component';
import {CreateTeamStepComponent} from './create-team-step/create-team-step.component';
import {AddFundraisersStepComponent} from './add-fundraisers-step/add-fundraisers-step.component';
import {CreateTroopTeamStepComponent} from './create-troop-team-step/create-troop-team-step.component';
import {CreateMinistryTeamStepComponent} from './create-ministry-team-step/create-ministry-team-step.component';

@Injectable({
  providedIn: 'root'
})
export class IntermediateStepResolver implements OnDestroy {
  /**
   * Title of the step. Used as a subtitle in a wizard.
   */
  public title: string;

  /**
   * Description of the step. Used to describe the step in the stepper component.
   */
  public description: string;

  public componentType: Type<IIntermediateStepComponent>;
  public funnelType: TeamNameFormatType;

  private destroy$ = new Subject<void>();

  constructor(private route: ActivatedRoute) {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.funnelType = (params.t && params.t in TeamNameFormatType) ? Number(params.t) : null;
        this.resolveStep();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Resolve the {@link IIntermediateStepComponent} to display based on the current route's funnel type.
   */
  private resolveStep(): Type<IIntermediateStepComponent> {
    this.description = 'Create a team';
    this.componentType = CreateTeamStepComponent;

    switch (this.funnelType) {
      case TeamNameFormatType.CLASSROOM:
        this.title = 'Fund My Classroom';
        this.componentType = CreateClassroomTeamStepComponent;
        return;

      case TeamNameFormatType.GENERAL:
      case TeamNameFormatType.SCOUT: // Scout should be the same as general, but is not due to a misunderstanding.
        this.title = null;
        return;

      case TeamNameFormatType.TROOP:
        this.title = 'Fund My Troop';
        this.componentType = CreateTroopTeamStepComponent;
        return;

      case TeamNameFormatType.MINISTRY:
        this.title = 'Fund My Ministry';
        this.componentType = CreateMinistryTeamStepComponent;
        return;

      default:
        this.title = null;
        this.description = 'Select fundraisers';
        this.componentType = AddFundraisersStepComponent;
        return;
    }
  }
}
