import { Inject, Injectable, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UtilityService } from '../../core';

@Injectable()
export class RecaptchaService {
  private readonly url: string;
  private readonly siteKey: string;

  private ready$ = new ReplaySubject(1);
  // @ts-ignore
  private grecaptcha: ReCaptchaV2.ReCaptcha;

  constructor(@Inject(DOCUMENT) private document: Document,
              private util: UtilityService,
              private zone: NgZone) {
    this.siteKey = environment.google.recaptchaSiteKey;
    if (!this.siteKey) {
      throw new Error('ReCaptcha site key is not set.');
    }

    this.url = 'https://www.google.com/recaptcha/enterprise.js?render=' + this.siteKey;
    this.init().then();
  }

  /**
   * Whenever we're using recaptcha, we must show the badge or the legal text.
   * https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
   */
  public showBadge(): void {
    this.setBadgeVisible(true);
  }

  public hideBadge(): void {
    this.setBadgeVisible(false);
  }

  public setBadgeVisible(visible: boolean): void {
    const badgeVisibleClass = 'grecaptcha-badge-visible';

    this.ready$.asObservable()
      .subscribe(() => {
        const badgeEls = this.document.getElementsByClassName('grecaptcha-badge');
        if (!badgeEls) return;

        for (let i = 0; i < badgeEls.length; i++) {
          const badgeEl = badgeEls.item(i);

          if (badgeEl && badgeEl instanceof HTMLElement) {
            if (visible) badgeEl.classList.add(badgeVisibleClass);
            else badgeEl.classList.remove(badgeVisibleClass);
          }
        }
      });
  }

  public execute(action: string): Observable<string> {
    const subject = new Subject<string>();

    const onError = err => {
      this.zone.run(() => {
        subject.error(err);
      });
    };

    return this.ready$.asObservable()
      .pipe(switchMap(() => {
        this.zone.runOutsideAngular(() => {
          try {
            this.grecaptcha
              .execute(this.siteKey, { action })
              .then(token => {
                this.zone.run(() => {
                  subject.next(token);
                  subject.complete();
                });
              }, onError);
          } catch (err) {
            onError(err);
          }
        });

        return subject.asObservable();
      }));
  }

  private async init(): Promise<void> {
    if (!(window as any).grecaptcha) {
      await this.util.loadScript(this.url, { defer: true });
    }

    this.grecaptcha = (window as any).grecaptcha.enterprise;

    this.grecaptcha.ready(() => {
      this.ready$.next(true);
    });
  }
}
