<ng-container *ngIf="isAutomaticallyAddingAllocation; else manuallySelect">
  <div class="row text-center" *ngIf="loadingAdd; else autoAddFailed">
    <div class="col-12">
      <div class="fa-5x color-primary">
        <i class="fas fa-spinner fa-spin"></i>
      </div>

      <div>Adding your {{teamId ? 'team' : 'fundraiser'}}...</div>
    </div>
  </div>

  <ng-template #autoAddFailed>
    <div class="row full-col">
      <div class="col-md-12">
        <alert type="danger" *ngIf="errorMessage">{{ errorMessage }}</alert>
      </div>
    </div>

    <div class="row text-center">
      <div class="col-md-12 col-actions">
        <button class="btn btn-default btn-padded mt-3" routerLink="/account">Cancel</button>
        <button class="btn btn-primary btn-padded mt-3" [disabled]="loadingAdd" (click)="addAllocations()">
          Try Again
        </button>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #manuallySelect>
  <div class="row text-center">
    <div class="col-md-12">
      <p>Now choose at least one fundraiser</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <form (ngSubmit)="searchFundraisers()">
        <div class="input-group">
          <input class="form-control py-2 border" type="search" name="searchInput"
                 placeholder="Search by fundraiser or promo code" [(ngModel)]="searchQuery" autofocus>
          <div class="input-group-append">
            <button class="btn btn-primary" type="submit" (click)="searchFundraisers()" aria-label="Search"><span
              class="fa fa-search"></span></button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row text-center" *ngIf="loadingSearch">
    <div class="col-md-12">
      <span class="fa fa-spinner fa-spin fa-lg mt-5"></span>
    </div>
  </div>

  <div class="row selections" *ngIf="fundraisers && fundraisers.length && !(teams && teams.length)">
    <div class="spinner-container" *ngIf="loadingTeams || isValidating">
      <span class="fa fa-spinner fa-spin fa-lg"></span>
    </div>

    <div class="list-group list-group-flush list-group-scroll">
      <label class="list-group-item" for="fundraiserRadio{{i}}" *ngFor="let fundraiser of fundraisers; let i = index"
             (click)="selectFundraiser(fundraiser)">
        <input type="radio" id="fundraiserRadio{{i}}" name="fundraiserRadioGroup" class="fundraiser-radio"
               [checked]="fundraiser === selectedParentFundraiser || fundraiser === selectedFundraiser">
        <fundraiser-description
          [topText]="fundraiser.communityName"
          [middleText]="fundraiser.name"
          [bottomText]="fundraiser.city + ', ' + fundraiser.state"
          [imageUrl]="fundraiser.imageUrl"></fundraiser-description>
      </label>
    </div>
  </div>

  <div class="row selections" *ngIf="teams && teams.length">
    <div class="team-instructions">Find the team you want to support</div>

    <div class="list-group list-group-flush list-group-scroll">
      <div class="list-group-item back-item" (click)="backToFundraisers()">
        <span class="fa fa-sm fa-arrow-left"></span> Back to fundraisers
      </div>
      <label class="list-group-item" for="teamRadio{{i}}" *ngFor="let team of teams; let i = index"
             (click)="selectTeam(team)">
        <input type="radio" id="teamRadio{{i}}" name="fundraiserRadioGroup" class="fundraiser-radio"
               [checked]="team === selectedFundraiser">
        {{team.name}}
      </label>
    </div>
  </div>

  <div class="row full-col">
    <div class="col-md-12">
      <alert type="danger" *ngIf="errorMessage" (onClose)="errorMessage = null" dismissible="true">
        {{ errorMessage }}
      </alert>
    </div>
  </div>

  <div class="row text-center">
    <div class="col-md-12 col-actions">
      <button class="btn btn-default btn-padded mt-3" routerLink="/account">Cancel</button>
      <button class="btn btn-primary btn-padded mt-3" [disabled]="!selectedFundraiser || loadingAdd" (click)="addAllocations()">
        Add Fundraiser
        <span *ngIf="loadingAdd" class="fa fa-spinner fa-spin"></span>
      </button>
    </div>
  </div>
</ng-template>
