import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

/**
 * Get the environment-driven URL an image on the CDN.
 */
@Injectable()
export class ImagesService {
  private brandName = environment.brandName.toLowerCase();

  get logoHorizontalWhite(): string {
    return this.get(`logos/${this.brandName}/white/logo-horizontal-white.png`);
  }

  get logo(): string {
    return this.get(`logos/${this.brandName}/logo-horizontal.png`);
  }

  get logoStacked(): string {
    return this.get(`logos/${this.brandName}/logo-stacked.png`);
  }

  get brandmark(): string {
    return this.get(`logos/${this.brandName}/brandmark.png`);
  }

  get brandmarkWhite(): string {
    return this.get(`logos/${this.brandName}/white/brandmark-white.png`);
  }

  get background(): string {
    return this.get('bg-bullseye-gradient.svg');
  }

  get appleAppStoreBadge(): string {
    return this.get('badge/apple_app_store.png');
  }

  get googleAppStoreBadge(): string {
    return this.get('badge/google_play.png');
  }

  get(file: string) {
    return `${environment.cdnUrl}${file}`;
  }
}
