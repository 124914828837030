import { Component, Input, OnInit } from '@angular/core';
import { CsvRowError } from '../../models/csv-response.model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-csv-error-table',
  templateUrl: './csv-error-table.component.html',
  styleUrls: ['./csv-error-table.component.scss']
})
export class CsvErrorTableComponent implements OnInit {
  @Input() set errors(errors) {
    this.dataSource = new MatTableDataSource(errors);
  }

  public dataSource: MatTableDataSource<CsvRowError> = new MatTableDataSource([]);
  public displayedColumns: string[] = [
    'row', 'name', 'status'
  ];

  constructor() { }

  ngOnInit() {
  }

}
