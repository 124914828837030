import { Injectable } from '@angular/core';
import { HttpService } from '../../http';
import { Logger } from '../../logger.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private http: HttpService,
              private logger: Logger) { }

  /**
   * Send an analytics event.
   * This creates a record in the auditclick table and creates an event in GoogleAnalytics.
   */
  public sendEvent(activityTypeId: number): void {
    this.http.post('/analytics/click', { body: { activityTypeId }})
      .subscribe(() => { /* Do nothing */ },
                 err => this.logger.error('Error sending analytics event:', err));
  }
}
