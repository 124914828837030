import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService, IEarnResponse } from '../../http';
import { Team } from '../../../shared/models/team.model';
import { withoutNullProperties } from '../../../shared/util';
import { TeamCreationValidation } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class LegacyTeamService {
  constructor(private http: HttpService) { }

  /**
   * Get the teams the user owns.
   */
  public getMyTeams(fundraiserId?: string): Observable<Team[]> {
    const options = {
      params: withoutNullProperties({ fundraiserId })
    };

    return this.http.get<IEarnResponse & { teams: Team[] }>('/me/team', options)
      .pipe(map(res => res.teams));
  }

  /**
   * Create a team the user owns.
   * @param fundraiserId - Fundraiser to create team under.
   * @param name - Public name of team.
   * @returns The id of the newly created team.
   */
  public createMyTeam(fundraiserId: string, name: string): Observable<string> {
    const options = {
      body: { fundraiserId, name }
    };

    return this.http.post<IEarnResponse & { teamId: string }>('/me/team', options)
      .pipe(map(res => res.teamId));
  }

  /**
   * Validate that a user can create a their own team under a fundraiser.
   */
  public validateCanCreateMyTeam(fundraiserId: string): Observable<TeamCreationValidation> {
    return this.http.get<IEarnResponse & TeamCreationValidation>('/me/team/validate', { params: { fundraiserId }});
  }

  public getTeamsForFundraiser(fundraiserId: string): Observable<Team[]> {
    return this.http.get<IEarnResponse & { teams: Team[] }>(`/v3/fundraiser/${fundraiserId}/team`)
      .pipe(map(res => res.teams));
  }
}
