import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AlertModule } from 'ngx-bootstrap/alert';
import { SharedModule } from '../../shared';
import { CreateMyTeamService } from './create-my-team.service';
import { CreateMyTeamDialogComponent } from './create-my-team-dialog/create-my-team-dialog.component';
import { AlreadyOwnedTeamsDialogComponent } from './already-owned-teams-dialog/already-owned-teams-dialog.component';

@NgModule({
    declarations: [
        AlreadyOwnedTeamsDialogComponent,
        CreateMyTeamDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        AlertModule.forRoot()
    ],
    providers: [
        CreateMyTeamService
    ]
})
export class CreateMyTeamModule { }
