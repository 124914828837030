import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputSearchComponent),
    multi: true
  }]
})
export class InputSearchComponent implements ControlValueAccessor {
  @Output() search = new EventEmitter<string>();
  @Input() placeholder = 'Search';
  public isDisabled = false;

  private _value: string;
  public get value() { return this._value; }
  public set value(value: string) {
    this._value = value;
    this.onChange(value);
  }

  private onChange: (_: string) => void = (_: string) => {};
  private onTouched: () => void = () => {};

  constructor() { }

  registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  public clearValue(): void {
    this.value = null;
    this.search.emit();
  }
}
