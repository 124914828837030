import { Component } from '@angular/core';
import { isNullOrEmpty } from '../../../../shared/util';
import { LegacyTeamService } from '../../../../core/services';
import { Logger } from '../../../../core';
import { CreateTeamStepComponentBase } from '../create-team-step-base.component';

@Component({
  selector: 'app-create-troop-team-step',
  templateUrl: './create-troop-team-step.component.html',
  styleUrls: ['./create-troop-team-step.component.scss']
})
export class CreateTroopTeamStepComponent extends CreateTeamStepComponentBase {
  public model: { councilName: string, troopName: string } = {
    councilName: null,
    troopName: null
  };

  public get teamName(): string {
    const nameParts: string[] = [ this.model.councilName, this.model.troopName ];
    return nameParts.filter(val => !isNullOrEmpty(val)).join(' ');
  }

  constructor(protected teamService: LegacyTeamService,
              protected logger: Logger) {
    super(teamService, logger);
  }
}
