import { Component, OnDestroy, Input } from '@angular/core';
import { BrowserDetectService, BrowserName, BrowserExtensionService } from '../../../../core';

@Component({
  selector: 'app-start-shopping',
  templateUrl: './start-shopping.component.html',
  styleUrls: ['./start-shopping.component.scss']
})
export class StartShoppingComponent implements OnDestroy {
  @Input() mode: 'signup' | 'signin';

  public readonly BrowserName = BrowserName;

  constructor(public browserDetect: BrowserDetectService,
              public browserExt: BrowserExtensionService) {
  }

  ngOnDestroy() {
    const contentCardEle = document.getElementById('layout-content-card-container');
    contentCardEle.classList.remove('full-width');
  }

  /**
   * Hook called when a step is selected (AKA displayed to the user).
   * When using {@link MatStepper}, all steps are loaded immediately. This means their life cycle hooks are called after
   * the page loads, rather than when the step is selected.
   */
  public onStepSelect(): void {
    const contentCardEle = document.getElementById('layout-content-card-container');
    contentCardEle.classList.add('full-width');
  }
}
