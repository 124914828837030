import { Component } from '@angular/core';
import { LegacyTeamService } from '../../../../core/services';
import { isNullOrEmpty } from '../../../../shared/util';
import { Logger } from '../../../../core';
import { CreateTeamStepComponentBase } from '../create-team-step-base.component';

interface MinistryNameParts {
  city: string;
  organizationName: string;
  useOfFunds: string;
}

@Component({
  selector: 'app-create-ministry-team-step',
  templateUrl: './create-ministry-team-step.component.html',
  styleUrls: ['./create-ministry-team-step.component.scss']
})
export class CreateMinistryTeamStepComponent extends CreateTeamStepComponentBase {
  public model: MinistryNameParts = {
    city: null,
    organizationName: null,
    useOfFunds: null
  };

  public get teamName(): string {
    const nameParts: string[] = [
      this.model.city, this.model.organizationName, this.model.useOfFunds
    ];

    return nameParts.filter(val => !isNullOrEmpty(val)).join(' ');
  }

  constructor(protected teamService: LegacyTeamService,
              protected logger: Logger) {
    super(teamService, logger);
  }
}
