import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Member, StatementReferenceType } from "app/shared/models";
import { DownloadStatementsOptions, GetStatementsForPeriodOptions, GetStatementsOptions, IStatements, StatementPeriodRange, StatementPeriods } from "app/shared/models/statement.model";
import { withoutNullProperties } from "app/shared/util";
import { IEarnResponse, IRequestOptions } from "../..";
import { HttpService, ResponseContentType } from "../../http";

@Injectable({
  providedIn: 'root'
})
export class StatementService {
  constructor(private http: HttpService) {}

  private periodToString(period: Date) {
    return `${period.getFullYear()}-${period.getMonth() + 1}-${period.getDate()}`;
  }

  private getPeriodsFromRange(statementPeriodRange: StatementPeriodRange): Date[] {
    /**
     * If no range is given, there are no available statement periods, so only return
     * the current month so that a user can select the current member list statement.
     */
    if (!statementPeriodRange.earliestPeriod && !statementPeriodRange.latestPeriod) return [ new Date() ];

    const range = this.getPeriodRangeAsDates(statementPeriodRange);

    const earliestPeriodMonthCount = range.earliestPeriod.getFullYear() * 12 + range.earliestPeriod.getMonth();
    const latestPeriodMonthCount = range.latestPeriod.getFullYear() * 12 + range.latestPeriod.getMonth();
    const monthDiffBtwnPeriods = latestPeriodMonthCount - earliestPeriodMonthCount;

    // Get periods, from newest to oldest.
    const periods = [];
    for (let i = monthDiffBtwnPeriods; i >= 0; i--) {
        const period = new Date(range.earliestPeriod);
        period.setMonth(period.getMonth() + i);
        periods.push(period);
    }

    return periods;
  }

  /**
   * Convert the period range to dates, limiting the earliest period to 24 months in the past.
   */
  private getPeriodRangeAsDates(range: StatementPeriodRange): { earliestPeriod: Date, latestPeriod: Date } {
    /**
     * The earliest period available will never be more than 24 months in the past.
     */
    const minPeriod = new Date();
    minPeriod.setMonth(minPeriod.getMonth() - 24);

    const earliestPeriod = (!range.earliestPeriod || new Date(range.earliestPeriod) < minPeriod)
      ? minPeriod
      : new Date(range.earliestPeriod);

    const latestPeriod = (!range.latestPeriod || new Date(range.latestPeriod) > new Date())
      ? new Date()
      : new Date(range.latestPeriod);

    return { earliestPeriod, latestPeriod };
  }

  /**
   * Get the periods available for a user to choose from.
   *
   * The earliest period available will never be more than 24 months in the past.
   */
  public getStatementPeriods(options: GetStatementsOptions): Observable<Date[]> {
    const reqOptions: IRequestOptions = {
      params: withoutNullProperties({
        recruitee: options.recruitee,
        communityId: options.communityId,
        fundraiserId: options.fundraiserId,
        referenceTypes: options.referenceTypes && options.referenceTypes.join(',')
      })
    };

    return this.http.get<StatementPeriodRange>('/statement/period', reqOptions)
      .pipe(map(statementPeriodRange => this.getPeriodsFromRange(statementPeriodRange)));
  }

  /**
   * Get the periods available for a user to choose from.
   *
   * The earliest period available will never be more than 24 months in the past.
   */
  public getStatementPeriodsForStatement(statementTypeId: number, options: GetStatementsOptions): Observable<Date[]> {
    const reqOptions = {
      params: withoutNullProperties(Object.assign({}, options)),
      body: {
        fundraiserId: options.fundraiserId,
        communityId: options.communityId
      }
    };
    return this.http.post<StatementPeriods>(`/statement/${statementTypeId}/period`, reqOptions)
      .pipe(map(x => {
        return x.periods.map(z => {
          const dateComponents = z.split('-');
          return new Date(Number(dateComponents[0]), Number(dateComponents[1]) - 1);
        });
      }));
  }

  /**
   * Get the statements
   */
  public getStatements(options: GetStatementsOptions): Observable<IStatements> {
    const reqOptions: IRequestOptions = {
      params: withoutNullProperties({
        communityId: options.communityId,
        fundraiserId: options.fundraiserId,
        referenceTypes: options.referenceTypes && options.referenceTypes.join(',')
      })
    };

    return this.http.get<IEarnResponse & { statements: IStatements }>(`/statement`, reqOptions)
      .pipe(map(response => response.statements));
  }

  /**
   * Send an email to the user with the statement files for the selected statements.
   */
  public adminSendEmails(options: {body: string, recipients: string[]} & DownloadStatementsOptions): Observable<any> {
    const period = options.period;
    const reqOptions = {
      body: {
        body: options.body,
        recipients: options.recipients,
        period: period ? this.periodToString(period) : null,
        statements: options.statements,
        references: options.references,
        recruitee: options.recruitee
      },
      responseType: 'blob' as ResponseContentType,
      observe: 'response'
    };

    /**
     * Yes, `/admin/admin`. The actual Earn2 endpoint is `/admin/statement/email`.
     * We used to have to manually map each API endpoint and many endpoints got mapped as `/admin/*` despite their
     * Earn2 endpoints not having the prefix. Earn2Controller automatically removes the first `/admin` so that
     * we didn't have to refactor all those other endpoints. Should be fixed at some point.
     */
    return this.http.post('/admin/admin/statement/email', reqOptions);
  }

  public getCommunityAndFundraiserAdmins(options: {fundraiserId: string, communityId: string}): Observable<(Member & {roleName: string})[]> {
    const reqOptions = {
      params: options
    };

    return this.http.get<any>('/member/communityfundraiseradmin', reqOptions).pipe(map(x => x.members));
  }
}
