import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { publishReplay, refCount, take } from 'rxjs/operators';
import * as deepEqual from 'fast-deep-equal';
import { GetCacheOptions, SetCacheOptions, SetIfNotExistsCacheOptions } from './cache-options.model';

interface CacheItem {
  // Args set when the item was cached.
  args?: any;
  obs$: Observable<any>;
}

/**
 * Service to cache an {@link Observable} and its results.
 */
@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache = new Map<string, CacheItem>();

  constructor() { }

  /**
   * Clear the entire cache.
   */
  public clear() {
    this.cache.clear();
  }

  /**
   * Delete the cached observable, provided the current args match the cached args or force is set to true.
   */
  public delete(key: string, options: GetCacheOptions & { force?: boolean } = {}): void {
    const cacheItem = this.cache.get(key);
    if (cacheItem && (options.force || deepEqual(cacheItem.args, options.args))) {
      this.cache.delete(key);
    }
  }

  /**
   * Get the cached observable, provided the current args match the cached args.
   */
  public get<T>(key: string, options: GetCacheOptions = {}): Observable<T> | null {
    const cacheItem = this.cache.get(key);
    return (cacheItem && deepEqual(cacheItem.args, options.args))
      ? cacheItem.obs$
      : null;
  }

  /**
   * Cache the observable with the provided args.
   * @returns The observable as a {@link ReplaySubject}. Use this instead of {@link obs$}.
   */
  public set<T>(key: string, obs$: Observable<T>, options: SetCacheOptions = {}): Observable<T> {
    const cacheObs$ = obs$.pipe(
      publishReplay(1, options.ttl),
      refCount(),
      take(1)
    );

    this.cache.set(key, { obs$: cacheObs$, args: options.args });

    return cacheObs$;
  }

  /**
   * Cache an observable, if it does not already exist or if {@link SetIfNotExistsCacheOptions.noCache} is true, and
   * @returns The observable as a {@link ReplaySubject}. Use this instead of {@link obs$}.
   */
  public setIfNotExists<T>(key: string, obs$: Observable<T>, options: SetIfNotExistsCacheOptions = {}): Observable<T> {
    if (!options.noCache) {
      const cachedObs$ = this.get<T>(key, options);
      if (cachedObs$) return cachedObs$;
    }

    return this.set<T>(key, obs$, options);
  }
}
