import { Component, Inject, OnInit, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';

export interface DialogButton {
  text: string;
  color?: ThemePalette;
  class?: string;
  click?: () => void;
  type?: 'basic' | 'flat';
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  title: string
  message: string
  buttons: DialogButton[]

  constructor(public dialogRef: MatDialogRef<DialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { title?: string, message: string, buttons: DialogButton[]}) {
    this.title = data.title
    this.message = data.message
    this.buttons = data.buttons
  }

  ngOnInit() { }

  public getBtnClass(btn: DialogButton): string {
    const additionalClasses = (btn.type !== 'flat') ? '' : 'btn btn-' + this.getThemeColorBtnClass(btn.color);
    return additionalClasses + ' ' + (btn.class || '');
  }

  /**
   * Convert a material color to a bootstrap theme color and get the corresponding button class name.
   */
  private getThemeColorBtnClass(color?: ThemePalette): string {
    switch (color) {
      case 'accent':
        return 'secondary';
      case 'primary':
      case 'warn':
        return color;
      default:
        return 'default';
    }
  }
}
