import { Injectable } from '@angular/core';
import { CookieService } from '../cookie.service';
import { CookieOptions, ICookie } from '../cookie.model';

@Injectable({
  providedIn: 'root'
})
export abstract class CookieBase implements ICookie {
  public readonly name: string;
  public readonly options: CookieOptions;

  protected constructor(protected cookieService: CookieService) {}

  public set(value: string): void {
    this.cookieService.set(this.name, value, this.options);
  }

  public get(): string {
    return this.cookieService.get(this.name);
  }

  public delete(): void {
    this.cookieService.delete(this.name, this.options);
  }
}
