import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { isUUID } from '../util';

export class AppValidators {
  public static equalTo(fieldName: string): ValidatorFn {
    return (control: AbstractControl): any => {
      const isValid = (control.root.value[fieldName] === control.value);
      return isValid
        ? null
        : { equalTo: true };
    };
  }

  public static validUUID(allowDashlessUUID = false, property: string = 'id'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = control.value && isUUID(control.value, allowDashlessUUID) || control.value && control.value[property] && isUUID(control.value[property], allowDashlessUUID);
      return isValid
        ? null
        : { validUUID: true };
    };
  }

  /**
   * Check that a control value is an object.
   * Treat nulls as valid. Let {@link Validators.required} handle null values.
   */
  public static validObject(control: AbstractControl): ValidationErrors | null {
    const isValid = !control.value || (typeof control.value === 'object');
    return isValid
      ? null
      : { validObject: true };
  }
}
