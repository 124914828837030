<form (ngSubmit)="onSubmit()" #signUpForm="ngForm">
  <div class="row">
    <div class="form-group col-sm-12 col-md-6">
      <label for="firstName">First Name</label>
      <input autocomplete="give-name" type="text" id="firstName" name="firstName" class="form-control" [disabled]="loading"
             [(ngModel)]="model.firstName" required autofocus>
    </div>
    <div class="form-group col-sm-12 col-md-6">
      <label for="lastName">Last Name</label>
      <input autocomplete="family-name" type="text" id="lastName" name="lastName" class="form-control" [disabled]="loading"
             [(ngModel)]="model.lastName" required>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-12">
      <label for="username">Username</label>
      <input autocomplete="username" type="text" id="username" name="username" class="form-control" [disabled]="loading"
             [(ngModel)]="model.username" required>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-12">
      <label for="email">Email</label>
      <input autocomplete="email" type="email" id="email" name="email" class="form-control" [disabled]="loading"
             [(ngModel)]="model.email" required email>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-sm-12 col-md-6">
      <label for="password">Password</label>
      <input autocomplete="password" type="password" id="password" name="password"
             class="form-control" [disabled]="loading" [(ngModel)]="model.password" #password="ngModel"
             [pattern]="formService.passwordValidation.pattern" required>
      <p class="text-danger" *ngIf="password.touched && password.invalid && password.control.errors?.pattern">
        {{formService.passwordValidation.error}}
      </p>
    </div>
    <div class="form-group col-sm-12 col-md-6">
      <label for="confirmPassword">Confirm Password</label>
      <input autocomplete="confirm-password" type="password" id="confirmPassword" name="confirmPassword"
             class="form-control" [disabled]="loading" [(ngModel)]="model.confirmPassword" #confirmPassword="ngModel"
             [pattern]="formService.passwordValidation.pattern" required>
      <p class="text-danger" *ngIf="confirmPassword.touched && confirmPassword.invalid && confirmPassword.control.errors?.pattern">
        {{formService.passwordValidation.error}}
      </p>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-4">
      <label for="zipCode">Zip Code</label>
      <input autocomplete="postal-code" type="text" id="zipCode" name="zipCode" class="form-control" [disabled]="loading"
             [(ngModel)]="model.zipCode" #zipCode="ngModel" [ngModelOptions]="{updateOn: 'blur'}" required [pattern]="formService.zipCodeValidation.pattern">
      <p class="text-danger" *ngIf="zipCode.control.errors?.invalidZip || zipCode.control.errors?.pattern">
        {{formService.zipCodeValidation.error}}
      </p>
    </div>
    <div class="form-group col-md-4">
      <label for="city">City</label>
      <input autocomplete="address-level2" type="text" id="city" name="city" class="form-control" [disabled]="loading"
             [(ngModel)]="model.city" required>
    </div>
    <div class="form-group col-md-4">
      <label for="state">State</label>
      <select autocomplete="address-level1" id="state" name="state" class="form-control"
              [disabled]="loading" [(ngModel)]="model.state" required>
        <option *ngFor="let state of formService.states" [value]="state.abbr">{{state.name}}</option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-12">
      <input type="checkbox" id="agreeToTerms" name="agreeToTerms" [(ngModel)]="model.agreeToTerms" required [disabled]="loading">
      <label for="agreeToTerms" id="agree-terms-label">
        &nbsp;I agree to <a class="app-link" [href]="websiteUrl + 'terms'" target="_blank">Terms</a> & <a
        class="app-link" [href]="websiteUrl + 'privacy'" target="_blank">Privacy Policy</a>
      </label>
    </div>
  </div>
  <div class="row">
    <alert [type]="alert.type" *ngIf="alert?.msg" (onClose)="alertClose()" dismissible="true" class="col-12">
      {{ alert.msg }}
    </alert>
  </div>
  <div class="row">
    <div class="col-12 submit-col text-center">
      <button type="submit" class="btn btn-primary center btn-padded" [disabled]="!signUpForm.form.valid || loading">
        <i class="fas fa-spinner fa-spin" *ngIf="loading"></i>
        <span *ngIf="!loading">Create Account</span>
      </button>
    </div>
  </div>
</form>
