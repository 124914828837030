import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
    this.auth.logout()
      .subscribe(
        () => {
          window.location.replace(environment.huterra.website.url);
        },
        () => {
          // Ignore error and redirect to main page
          window.location.replace(environment.huterra.website.url);
        }
      );
  }

  private goToLogin(): void {
    this.router.navigate(['/login'], { replaceUrl: true });
  }
}
