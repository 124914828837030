import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpService, IEarnResponse, IRequestOptions } from '../../http';
import { IUpdateMember, User } from '../../../shared/models';
import { CacheService } from '../utility/cache.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private readonly PROFILE_CACHE_KEY = 'getProfile';

  constructor(private http: HttpService,
              private cache: CacheService) { }

  /**
   * Get the profile of the current user.
   * @param noCache - Whether to use the cached user profile (if available) or pull from the server.
   */
  public getProfile(noCache = false): Observable<User> {
    const req$ = this.http.get<IEarnResponse & { profile: User }>('/me/profile')
      .pipe(map(res => res.profile));

    return this.cache.setIfNotExists(this.PROFILE_CACHE_KEY, req$, { ttl: 10 * 60 * 1000, noCache });
  }

  public updateProfile(user: IUpdateMember): Observable<User> {
    const result = this.http.put<IEarnResponse & { profile: User }>('/me/profile', {
      body: {
        profile: user
      }
    }).pipe(map(res => res.profile));

    this.cache.delete(this.PROFILE_CACHE_KEY)

    return result
  }

  public verifyEmail(email: string, code: string): Observable<IEarnResponse> {
    const reqOptions: IRequestOptions = { params: { email: encodeURIComponent(email), code } };
    return this.http.post<IEarnResponse>('/me/verify', reqOptions)
      .pipe(tap(() => {
        this.cache.delete(this.PROFILE_CACHE_KEY);
      }));
  }

  public resendVerificationEmail(): Observable<void> {
    return this.http.post('/me/verify/resend');
  }

  public updatePassword(oldPassword: string, newPassword: string, confirmPassword: string) {
    return this.http.put<IEarnResponse & { profile: User }>('/me/password', {
      body: {
        oldPassword,
        newPassword,
        confirmPassword
      }
    });
  }

  public requestDeletion(): Observable<IEarnResponse> {
    return this.http.post('/me/requestAccountDeletion');
  }
}
