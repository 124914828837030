const PHONE_NUMBER_RE = /^(\+\d{1,2}\s)?\(?(\d{3})\)?[\s.-](\d{3})[\s.-](\d{4})$/;

export interface NormalizePhoneNumberOptions {
  separators?: boolean;
}

const defaultNormalizeOptions: NormalizePhoneNumberOptions = { separators: true };

export function normalizePhoneNumber(
  phoneNumber: string,
  options?: NormalizePhoneNumberOptions,
): string {
  options = {
    ...defaultNormalizeOptions,
    ...options,
  };

  const match = phoneNumber.match(PHONE_NUMBER_RE);
  if (!match) return phoneNumber;

  const countryCode = match[1] ? match[1].trim() : '';

  if (options.separators) {
    const components = [countryCode, `(${match[2]})`, `${match[3]}-${match[4]}`];
    return components.join(' ');
  }

  return `${countryCode}${match.slice(1).join('')}`;
}
