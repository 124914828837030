<ng-content></ng-content>

<div
  class="read-more-prompt"
  *ngIf="hasOverflow && !expanded"
  (click)="toggleExpanded()"
>
  <a href="javascript:void(0);" class="read-more-prompt__text">
    Read More
    <mat-icon>expand_more</mat-icon>
  </a>
</div>
